import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  TextField,
  Grid,
  Box,
  Divider,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Modal,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { getPostData } from "../redux/action/user_action";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
let openEditDrawerFlag = false;
const empCollectionRef = collection(db, "adminData");
const Usermanage = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [usertype, setUsertype] = useState("");
  const [orderList, setOrderList] = useState(false);
  const [riderList, setRiderList] = useState(false);
  const [customerList, setCustomerList] = useState(false);
  const [issueTicket, setIssueTicket] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checkboxValid, setCheckboxValid] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [usertypeError, setUsertypeError] = useState("");
  const [formattedData, setFormattedData] = useState([]);
  const userdata = useSelector((state) => state.post);
  const [configuration, setConfiguration] = useState();
  const [ddata, setDdata] = useState();

  useEffect(() => {
    dispatch(getPostData());
  }, []);
  useEffect(() => {
    if (userdata.data.length) {
      setConfiguration(userdata);
      setDdata(userdata.data); // Set ddata with the fetched data
    } else {
      console.log("Data is not available");
    }
  }, [userdata]);

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2", // Default color
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff", // Hover color
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d{0,10}$/.test(inputValue)) {
      setPhone(inputValue);
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid phone number");
    }
  };

  const handleCheckboxChange = (e) => {
    setOrderList(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange1 = (e) => {
    setRiderList(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange2 = (e) => {
    setCustomerList(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange3 = (e) => {
    setIssueTicket(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange4 = (e) => {
    setAdmin(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const validateInput = (inputValue, fieldName) => {
    if (inputValue === "") {
      switch (fieldName) {
        case "name":
          setNameError("Please enter name");
          break;
        case "email":
          setEmailError("Please enter email");
          break;
        case "phone":
          setPhoneError("Please enter Phone");
          break;
        case "password":
          setPasswordError("Please enter Password");
          break;
        case "usertype":
          setUsertypeError("Please enter User Type");
          break;

        default:
          break;
      }
      return false;
    }
    // Clear error when validation succeeds
    switch (fieldName) {
      case "name":
        setNameError("");
        break;
      case "email":
        setEmailError("");
        break;
      case "phone":
        setPhoneError("");
        break;
      case "usertype":
        setUsertypeError("");
        break;

      default:
        break;
    }
    return true;
  };

  // const validateInput = (inputValue) => {
  //   return inputValue !== ""; // Basic validation: check if a value is selected
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const isNameValid = validateInput(name, "name");
    const isEmailValid = validateInput(email, "email");
    const isPhoneValid = validatePhone();
    const isPasswordValid = validateInput(password, "password");
    const isUserTypeValid = validateInput(usertype, "usertype");
    if (
      isNameValid &&
      isEmailValid &&
      isPhoneValid &&
      isPasswordValid &&
      isUserTypeValid
    ) {
      try {
        // Create a reference to your Firestore collection
        const notificationsRef = collection(db, "adminData");

        // Add the new document to the collection

        const docRef = await addDoc(notificationsRef, {
          UserId: "User" + Math.ceil(Math.random() * 10000),
          Name: name,
          Email: email,
          Phone: phone,
          Password: password,
          UserType: usertype,
          OrderList: orderList,
          RiderList: riderList,
          CustomerList: customerList,
          IssueTicket: issueTicket,
          Admin: admin,
        });

        console.log("Data added successfully! Document reference:", docRef.id);

        // Reset form fields and errors
        handleClose1();
        setName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setUsertype("");
      } catch (error) {
        console.error("Error adding document:", error);
      }
    } else {
      console.log("Form validation failed");
    }
  };
  // Function to validate phone number
  const validatePhone = () => {
    if (phone === "") {
      setPhoneError("Please enter Phone");
      return false;
    } else {
      setPhoneError(""); // Clear phone error when input is valid
      return true;
    }
  };

  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),
  };
  const handleOpen = (item, row) => {
    for (let i = 0; i < configuration.data.length; i++) {
      if (item[0] === configuration.data[i].UserId) {
        // Set state variables based on Firestore values
        setOrderList(configuration.data[i].OrderList || false);
        setRiderList(configuration.data[i].RiderList || false);
        setCustomerList(configuration.data[i].CustomerList || false);
        setIssueTicket(configuration.data[i].IssueTicket || false);
        setAdmin(configuration.data[i].Admin || false);

        // Set formatted data for Drawer
        return (
          setFormattedData([configuration.data[i]]),
          openEditDrawerFlag ? setOpenDrawer(true) : null
        );
      }
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
    openEditDrawerFlag = false;
  };
  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsers()
      .then((users) => {
        const filteredUsers = users.filter(
          (user) => user.UserId === formattedData[0].UserId
        );
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id;
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            ...formattedData[0],
            // Update the properties with the checkbox values
            OrderList: orderList,
            RiderList: riderList,
            CustomerList: customerList,
            IssueTicket: issueTicket,
            Admin: admin,
          };

          alert("Successfully Updated!!!");
          setEditMode(false);

          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleChange = (e, i, fieldName) => {
    setFormattedData((items) => {
      return items?.map((list, index) => {
        if (index === i) {
          return { ...list, [fieldName]: e.target.value };
        }
        return list;
      });
    });
  };

  const columns = [
    {
      name: "UserId",
      label: "UserId",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Phone",
      label: "Phone",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Password",
      label: "Password",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "UserType",
      label: "Role",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "OrderList",
      label: "Order",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <Checkbox checked={value} disabled />
        ),
      },
    },
    {
      name: "RiderList",
      label: "Rider",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <Checkbox checked={value} disabled />
        ),
      },
    },
    {
      name: "CustomerList",
      label: "Customer",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <Checkbox checked={value} disabled />
        ),
      },
    },
    {
      name: "IssueTicket",
      label: "Issue ticket",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <Checkbox checked={value} disabled />
        ),
      },
    },
    {
      name: "Admin",
      label: "Admin",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <Checkbox checked={value} disabled />
        ),
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];
  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;
      return rowData;
    };
    const handleDeleteClick = async () => {
      const userId = tableMeta.rowData[0]; // Assuming the first column contains the UserId

      try {
        const docRef = doc(empCollectionRef, userId);
        await deleteDoc(docRef);
        alert("Successfully Deleted!!!");
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => (openEditDrawerFlag = !openEditDrawerFlag)}
          >
            <EditIcon fontSize="small" />
          </Button>
          {/* <Button
            m={1}
            variant="contained"
            size="small"
            style={{ backgroundColor: "red" }}
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </Button> */}
        </Stack>
      </>
    );
  };

  return (
    <div style={{ padding: "10px" }}>
      <Grid
        item
        xs={6}
        sm={6}
        lg={4}
        xl={2}
        sx={{ maxWidth: "20%" }}
        style={pointerStyle}
      >
        <Paper
          onClick={handleOpen1}
          style={
            isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
          }
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box
                borderRadius="50%"
                width="30px"
                height="40px"
                style={{}}
              ></Box>
            </div>
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              ></Typography>
              <Typography style={{ color: "black" }}>
                <Box fontSize={12}>Create user</Box>
              </Typography>
            </div>
            <div />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography variant="h6" align="left">
              User Management
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose1}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(""); // Clear error when input changes
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && nameError && (
                  <span style={{ color: "red" }}>{nameError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && emailError && (
                  <span style={{ color: "red" }}>{emailError}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Phone"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange} // Use the new handler
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && phoneError && (
                  <span style={{ color: "red" }}>{phoneError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && passwordError && (
                  <span style={{ color: "red" }}>{passwordError}</span>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    User Type
                  </InputLabel>
                  <Select
                    value={usertype}
                    onChange={(e) => {
                      setUsertype(e.target.value);
                      setUsertypeError("");
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // required={true}
                    label="User Type"
                  >
                    <MenuItem value="Super Admin">Super Admin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                  {isSubmitted && usertypeError && (
                    <span style={{ color: "red" }}>{usertypeError}</span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <FormGroup
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderList}
                    onChange={handleCheckboxChange}
                  />
                }
                label="order list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={riderList}
                    onChange={handleCheckboxChange1}
                  />
                }
                label="Rider list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customerList}
                    onChange={handleCheckboxChange2}
                  />
                }
                label="Customer list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={issueTicket}
                    onChange={handleCheckboxChange3}
                  />
                }
                label="Issue ticket"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={admin} onChange={handleCheckboxChange4} />
                }
                label="Admin"
              />
            </FormGroup>
            {isSubmitted && !checkboxValid && (
              <span style={{ color: "red" }}>
                Please select at least one checkbox
              </span>
            )}
            <Button
              sx={{
                display: "flex",
                minWidth: 220,
                margin: "0 auto",
                marginTop: "5%",
              }}
              type="submit"
              ml={10}
              variant="contained"
              size="large"
              color="primary"
              mt={3}
              //onClick={handleSave}
            >
              Create user
            </Button>
          </Box>
        </form>
      </Modal>
      <Grid sx={{ mt: 1 }}>
        <MUIDataTable
          title="Show user details"
          data={ddata ? ddata : configuration?.data}
          columns={columns}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index) => {
          console.log("Items+++++++++++++++++++++++", item);
          return (
            <>
              <div style={{ padding: 20, width: "985px" }}>
                <Typography variant="h4" style={{ marginBottom: 10 }}>
                  Add vehicle all charges
                </Typography>

                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography variant="body1">user details</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="text"
                          id="outlined-disabled"
                          value={item.Name}
                          onChange={(event) =>
                            handleChange(event, index, "Name")
                          }
                          label="Name"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="text"
                          id="outlined-disabled"
                          label="Email"
                          value={item.Email}
                          onChange={(event) =>
                            handleChange(event, index, "Email")
                          }
                          defaultValue="Hello World"
                        />
                      </Grid>
                    </Grid>

                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="number"
                            id="outlined-disabled"
                            label="Phone"
                            value={item.Phone}
                            onChange={(event) =>
                              handleChange(event, index, "Phone")
                            }
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="password"
                            id="outlined-disabled"
                            label="password"
                            value={item.Password}
                            onChange={(event) =>
                              handleChange(event, index, "Password")
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControl
                            sx={{ mt: 3, minWidth: "100%" }}
                            variant="outlined"
                            m={2}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              User Type
                            </InputLabel>
                            <Select
                              disabled={!editMode}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="User Type"
                              value={item.UserType}
                              onChange={(event) =>
                                handleChange(event, index, "UserType")
                              }
                            >
                              <MenuItem value="Super Admin">
                                Super Admin
                              </MenuItem>
                              <MenuItem value="Admin">Admin</MenuItem>
                              <MenuItem value="Manager">Manager</MenuItem>
                              <MenuItem value="User">User</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormGroup sx={{ flexDirection: "row" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!editMode}
                                  checked={orderList}
                                  onChange={(e) =>
                                    setOrderList(e.target.checked)
                                  }
                                />
                              }
                              label="order list"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!editMode}
                                  checked={riderList}
                                  onChange={(e) =>
                                    setRiderList(e.target.checked)
                                  }
                                />
                              }
                              label="Rider list"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!editMode}
                                  checked={customerList}
                                  onChange={(e) =>
                                    setCustomerList(e.target.checked)
                                  }
                                />
                              }
                              label="Customer list"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!editMode}
                                  checked={issueTicket}
                                  onChange={(e) =>
                                    setIssueTicket(e.target.checked)
                                  }
                                />
                              }
                              s
                              label="Issue ticket"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!editMode}
                                  checked={admin}
                                  onChange={(e) => setAdmin(e.target.checked)}
                                />
                              }
                              s
                              label="Admin"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                </Box>
              </div>
              {editMode ? (
                <Button
                  sx={{ m: 3, maxWidth: "70px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={handleUpdate}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  sx={{ m: 3, maxWidth: "70px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={handleEditButtonClick}
                >
                  Edit
                </Button>
              )}
            </>
          );
        })}
      </Drawer>
    </div>
  );
};

export default Usermanage;
