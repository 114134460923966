import { AddCircle } from "@mui/icons-material";
import {
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPostData } from "../redux/action/customer_action";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import {
  validateAccountNum,
  validateAlphabetsOnly,
  validateMobileNum,
  validateNumariAndCapital,
} from "../common/validation";
const empCollectionRef = collection(db, "customerData");

const Editcustomer = (props) => {
  const dispatch = useDispatch();
  const EditData = useSelector((state) => state.post);
  const [edata, setEdata] = useState();
  const [udata, setUdata] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [validationObject, setvalidationObject] = useState({
    mobileNumber: {
      error: false,
      errMessage: "",
    },
    accountName: {
      error: false,
      errMessage: "",
    },
    pan: {
      error: false,
      errMessage: "",
    },
    ifscCode: {
      error: false,
      errMessage: "",
    },
    bankName: {
      error: false,
      errMessage: "",
    },
  });

  const validateAllField = (name, event) => {
    switch (name) {
      case "mobileNumber":
        validationObject[event.target.name] = validateMobileNum(event);
        break;
      case "accountName":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "userName":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "pan":
        validationObject[event.target.name] = validateNumariAndCapital(event);
        break;
      case "ifscCode":
        validationObject[event.target.name] = validateNumariAndCapital(event);
        break;
    }
  };
  useEffect(() => {
    dispatch(getPostData());
  }, []);

  useEffect(() => {
    if (EditData.data.length) {
      setEdata(EditData);
    } else {
      console.log("Data is not available");
    }
  }, [EditData]);

  // const data1 = [edata];
  console.log(edata?.data, "00000000");
  console.log(props.dataMain.state.from[0], "+++----0000");

  useEffect(() => {
    for (let i = 0; i < edata?.data.length; i++) {
      if (props.dataMain.state.from[0] === edata?.data[i].customerId) {
        return setUdata([edata?.data[i]]);
      }
    }
  }, [edata?.data, props.dataMain.state.from]);

  const handleChange = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return { ...list, [e.target.name]: e.target.value };
        }
        return list;
      });
    });
  };

  const handleChanges = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return {
            ...list,
            pickUpLocation: {
              ...list.pickUpLocation,
              [e.target.name]: e.target.value,
            },
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleChanged = (e, i) => {
    // console.log(e.target.name , "@@@@2");
    setUdata((item) => {
      return item?.map((list, index) => {
        // console.log({...list} , "@@@11133");
        if (index === i) {
          return {
            ...list,
            paymentInformation: {
              ...list.paymentInformation,
              [e.target.name]: e.target.value,
            },
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };
  const handleChangies = (e, i) => {
    console.log(e.target.name, "@@@@2");

    setUdata((item) => {
      return item?.map((list, index) => {
        console.log({ ...list.dropLocations }, "-----");
        if (index === i) {
          const updatedDropLocations = list.dropLocations.map((location) => {
            return {
              ...location,
              [e.target.name]: e.target.value,
            };
          });

          return {
            ...list,
            dropLocations: updatedDropLocations,
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  console.log(udata, "@@@@@123");

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };

    getUsers()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter(
          (user) => user.customerId === udata[0].customerId
        );
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            ...udata[0], // Use the desired array element or properties here
          };
          alert("Succesfully Updated!!!");
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Edit Customer details
            </Typography>
            <Paper mt={9}>
              {udata?.map((item, index) => {
                console.log("Inside map function:", item); // Add this line
                return (
                  <form autoComplete="off" key={index}>
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="User Name"
                      name="userName"
                      value={item.userName}
                      onChange={(event) => {
                        handleChange(event, index);
                        validateAllField("userName", event);
                      }}
                      variant="outlined"
                      error={validationObject.userName?.error}
                      helperText={validationObject.userName?.errMessage}
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Mobile Number"
                      name="mobileNumber"
                      type="text"
                      value={item.mobileNumber}
                      onChange={(event) => {
                        handleChange(event, index);
                        validateAllField("mobileNumber", event);
                      }}
                      variant="outlined"
                      error={validationObject.mobileNumber?.error}
                      helperText={validationObject.mobileNumber?.errMessage}
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Email"
                      name="email"
                      value={item.email}
                      onChange={(e) => handleChange(e, index)}
                      variant="outlined"
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Refer Code"
                      name="referCode"
                      type="text"
                      value={item.referCode}
                      onChange={(e) => handleChange(e, index)}
                      variant="outlined"
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Applied Refer Code"
                      name="appliedReferCode"
                      value={item.appliedReferCode}
                      onChange={(e) => handleChange(e, index)}
                      variant="outlined"
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Reference Amount"
                      name="Reference amount"
                      // value={taskData.task_remark}
                      // onChange={addTaskData}
                      variant="outlined"
                      m={2}
                    />
                    {/* <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Subscribed topic"
                      name="Subscribed topic"
                      variant="outlined"
                      m={2}
                    /> */}
                    {/* <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Device list"
                      name="Device list"
                      variant="outlined"
                      m={2}
                    /> */}
                    <FormControl
                      sx={{
                        m: 3,
                        minWidth: 220,
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Customer Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name={"customerType"}
                        value={item.customerType}
                        onChange={(event) => {
                          handleChange(event, index);
                        }}
                        label=" CustomerType"
                      >
                        <MenuItem value="Basic">{"Basic"}</MenuItem>
                        <MenuItem value="Premium">{"Premium"}</MenuItem>
                        {/* <MenuItem value="NON VIP">{"NON VIP"}</MenuItem> */}
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Delivered Count"
                      name="DeliveredCount"
                      // value={taskData.task_remark}
                      // onChange={addTaskData}
                      variant="outlined"
                      m={2}
                    />
                    <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="city"
                      name="city"
                      value={item.city}
                      onChange={(e) => handleChange(e, index)}
                      variant="outlined"
                      m={2}
                    />
                    <Divider my={6} />

                    <Button
                      sx={{ m: 3, minWidth: 220 }}
                      type="submit"
                      ml={10}
                      variant="contained"
                      size="large"
                      color="primary"
                      mt={3}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </form>
                );
              })}
            </Paper>
          </CardContent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Editcustomer;
