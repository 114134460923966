import React, { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Transaction from "../components/Transaction";
import { useLocation } from "react-router-dom";

export default function TransactionDetails() {
  const location = useLocation();
  const walletId = new URLSearchParams(location.search).get("walletId");

  return (
    <>
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 0.3 }}>
            <Transaction />
          </Box>
        </Box>
      </div>
    </>
  );
}
