// TransactionDetails.js

import React, { useEffect, useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { SignalCellularNull } from "@mui/icons-material";

export default function Transaction() {
  const location = useLocation();
  const walletId = new URLSearchParams(location.search).get("walletId");

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch and set transactions based on the walletId
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const transactionsRef = collection(
          db,
          "walletData",
          walletId,
          "transactions"
        );
        const transactionsQuery = query(transactionsRef);
        const transactionsSnapshot = await getDocs(transactionsQuery);
        const transactionsData = transactionsSnapshot.docs.map((doc) =>
          doc.data()
        );
        setTransactions(transactionsData);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    if (walletId) {
      fetchTransactions();
    }
  }, [walletId]);

  function formatTime(sec) {
    const date = new Date(sec * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 in 12-hour format

    return `${date.toLocaleDateString()} :: ${formattedHours}:${minutes} ${amOrPm}`;
  }

  return (
    <div>
      {loading && <p>Loading transactions...</p>}
      {transactions.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>TransactionId</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>Date & Time</TableCell>
                <TableCell align="right">Transaction Mode</TableCell>
                <TableCell align="right">Transaction Amount</TableCell>
                <TableCell align="right">Transaction Status</TableCell>
                <TableCell align="right">Transaction Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow
                  key={transaction.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {transaction.transactionId || "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {transaction.orderId || "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatTime(transaction.dateTime.seconds)}
                  </TableCell>
                  <TableCell align="right">
                    {transaction.transactionMode}
                  </TableCell>
                  <TableCell align="right">
                    {transaction.transactionAmount}
                  </TableCell>
                  <TableCell align="right">
                    {transaction.transactionStatus}
                  </TableCell>
                  <TableCell align="right">
                    {transaction.transactionType}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {transactions.length === 0 && !loading && (
        <p>No transactions found for this wallet ID.</p>
      )}
    </div>
  );
}
