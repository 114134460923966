import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getPostData } from "../redux/action/customer_discount_action";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import {
  Button,
  TextField,
  Grid,
  Box,
  Stack,
  Divider,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Modal,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
let openEditDrawerFlag = false;
const CustomerDiscount = () => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [offercodeError, setOffercodeError] = useState("");
  const [lastDigitError, setLastDigitError] = useState("");
  const [cities, setCities] = useState([]);
  const [ddata, setDdata] = useState();
  const [ridersData, setRidersData] = useState();
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityError, setSelectedCityError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);
  const [offercode, setOffercode] = useState("");
  const userdata = useSelector((state) => state.post);
  const [lastDigit, setLastDigit] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [edata, setEdata] = useState();
  const [udata, setUdata] = useState([]);
  const [configuration, setConfiguration] = useState();
  const [formattedData, setFormattedData] = useState([]);

  const empCollectionRef = collection(db, "serviceable_places");
  const empCollectionRefPromo = collection(db, "promo_codes");

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  useEffect(() => {
    if (userdata.data.length) {
      console.log("userData", userdata);
      setConfiguration(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]);

  const handleOpen = (item, row) => {
    console.log(item, "11111111111!!!");
    for (let i = 0; i < configuration.data.length; i++) {
      if (item[0] === configuration.data[i].promo_id) {
        return setFormattedData([configuration.data[i]]), setOpenDrawer(true);
      }
    }
  };

  useEffect(() => {
    const citiesList = () => {
      const getCities = async () => {
        try {
          const snapshot = await getDocs(empCollectionRef);
          console.log("snapshot++++++", snapshot);
          return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error retrieving users:", error);
          return [];
        }
      };
      getCities()
        .then((users) => {
          setCities(users);
          const empDocRef = doc(empCollectionRef);
          console.log("empDocRef++++++++++++", empDocRef);
        })
        .catch((error) => console.error(error));
    };
    citiesList();
  }, []);

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2",
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff",
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleDeliverClick = (e) => {
    setSelectedCity(e.target.value);
    console.log("ccdcdcd", ridersData);
    const DeliveredRows = ridersData?.data.filter(
      (row) => row.workingCity === e.target.value
    );
    setDdata(DeliveredRows);
  };

  const formatTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleOffercodeChange = (e) => {
    const inputValue = e.target.value;
    setOffercode(inputValue);

    const offerCodePattern = /^[a-zA-Z]+(\d+)$/;

    if (offerCodePattern.test(inputValue)) {
      // setLastDigit(inputValue.match(/\d+$/)[0]);
      // setLastDigitError("");
    } else {
      setLastDigit("");
      //  setLastDigitError("Invalid offer code format");
    }
  };

  const formatSelectedDate = (dateString) => {
    const options = {
      year: "2-digit",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setStartDateError("");
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setEndDateError("");
  };

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const promoCodesSnapshot = await getDocs(collection(db, "promo_codes"));
        const promoCodesData = promoCodesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDdata(promoCodesData);
      } catch (error) {
        console.error("Error fetching promo codes:", error);
      }
    };

    fetchPromoCodes();
  }, []);

  const handleChange = (e, i, fieldName) => {
    setFormattedData((items) => {
      return items?.map((list, index) => {
        if (index === i) {
          return { ...list, [fieldName]: e.target.value };
        }
        return list;
      });
    });
  };
  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!offercode) {
      setOffercodeError("Offer code is required");
      isValid = false;
    } else {
      setOffercodeError("");
    }

    if (!lastDigit) {
      setLastDigitError("Enter a value");
      isValid = false;
    } else {
      setLastDigitError("");
    }
    if (!startDate) {
      setStartDateError("Start date is required");
      isValid = false;
    } else {
      setStartDateError("");
    }

    if (!endDate) {
      setEndDateError("End date is required");
      isValid = false;
    } else {
      setEndDateError("");
    }

    if (!selectedCity) {
      setSelectedCityError("City is required");
      isValid = false;
    } else {
      setSelectedCityError("");
    }
    if (!customer) {
      setCustomerError("Customer type is required");
      isValid = false;
    } else {
      setCustomerError("");
    }

    if (isValid) {
      try {
        const empCollectionConfig = collection(db, "promo_codes");

        const docRef = doc(empCollectionConfig);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document already exists:", docSnap.data());
        } else {
          let veh = "promo";

          const newData = {
            promo_id: veh + Math.ceil(Math.random() * 10000),
            code_name: offercode,
            discount: lastDigit,
            city: selectedCity,
            user_type: customer,
            valid_from: startDate,
            valid_to: endDate,
          };

          await setDoc(docRef, newData);

          console.log("Data added successfully!");
        }
      } catch (error) {
        console.error("Error adding document:", error);
      }
      handleClose1();
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRefPromo);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsers()
      .then((users) => {
        console.log(formattedData, "12233");
        const filteredUsers = users.filter(
          (user) => user.promo_id === formattedData[0].promo_id
        );
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRefPromo, docId);

          const updateData = {
            ...formattedData[0], // Use the desired array element or properties here
          };

          alert("Succesfully Updated!!!");
          setEditMode(false);
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const empCollectionConfig = collection(db, "promo_codes");
      if (id === null) {
        return;
      }
      const docId = id;
      const docRef = doc(empCollectionConfig, docId);

      if (window.confirm("Are you sure?") != true) {
        console.log("Not deleted");
      } else {
        await deleteDoc(docRef);
        // if (userdata.data.length) {
        // setConfiguration(userdata);
        dispatch(getPostData());

        // }
        console.log("Data deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),
  };
  const columns = [
    {
      name: "promo_id",
      label: "ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "valid_from",
      label: "Valid from",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "valid_to",
      label: "Valid up",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "code_name",
      label: "Offer code",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "discount",
      label: "Value",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "city",
      label: "city",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "user_type",
      label: "Customer type",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];

  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;
      return rowData;
    };

    const handleDelete = async (e, id) => {
      e.preventDefault();
      try {
        const empCollectionConfig = collection(db, "promo_codes");

        if (id === null) {
          return;
        }

        const docRef = doc(empCollectionConfig, id);

        if (window.confirm("Are you sure?")) {
          await deleteDoc(docRef);
          dispatch(getPostData());
          console.log("Data deleted successfully!");
        } else {
          console.log("Not deleted");
        }
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => (openEditDrawerFlag = !openEditDrawerFlag)}
          >
            <EditIcon fontSize="small" />
          </Button>
          {/* <Button
            m={1}
            variant="contained"
            size="small"
            style={{ backgroundColor: "red" }}
            onClick={(e) => {
              const rowData = tableMeta.rowData;
              let id = null;
              if (rowData) {
                id = rowData[0];
              }
              handleDelete(e, id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </Button> */}
        </Stack>
      </>
    );
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleSubmitButtonClick = () => {
    setEditMode(false);
  };
  const handleEditButtonClick1 = () => {
    setEditMode1(true);
  };

  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Order Discount Offer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Grid
                item
                xs={6}
                sm={6}
                lg={4}
                xl={2}
                sx={{ maxWidth: "20%" }}
                style={pointerStyle}
              >
                <Paper
                  onClick={handleOpen1}
                  style={
                    isHovered2
                      ? { ...paperStyle, ...paperHoverStyle }
                      : paperStyle
                  }
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Box
                        borderRadius="50%"
                        width="30px"
                        height="40px"
                        style={{ display: "flex", marginLeft: "10px" }}
                      ></Box>
                    </div>
                    <div>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          color: "black",
                          marginBottom: "0px",
                          marginTop: "5px",
                        }}
                      ></Typography>
                      <Typography style={{ color: "black" }}>
                        <Box fontSize={12}>Add New Discount Offer</Box>
                      </Typography>
                    </div>
                    <div />
                  </div>
                </Paper>
              </Grid>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <form onSubmit={handleSave}>
                  <Box sx={style}>
                    <Typography variant="h6" align="left">
                      Offer Management
                    </Typography>
                    <Divider sx={{ mt: 2 }} />
                    <IconButton
                      style={{ position: "absolute", top: "0", right: "0" }}
                      onClick={handleClose1}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Grid container spacing={2} sx={{ mt: "20" }}>
                      <Grid item xs={6}>
                        <TextField
                          label="offer code"
                          name="offercode"
                          value={offercode}
                          onChange={handleOffercodeChange} // Use the event handler here
                          sx={{
                            marginTop: "30px",
                            width: "267px",
                            textTransform: "uppercase !important",
                          }}
                          style={{ textTransform: "uppercase" }}
                          variant="outlined"
                          error={!!offercodeError}
                          helperText={offercodeError}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="value"
                          name="value"
                          type="text"
                          value={lastDigit}
                          onChange={(e) => setLastDigit(e.target.value)}
                          sx={{ marginTop: "30px", width: "267px" }}
                          variant="outlined"
                          error={!!lastDigitError}
                          helperText={lastDigitError}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: "20" }}>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ mt: 3, minWidth: "100%" }}
                          variant="outlined"
                          m={2}
                        >
                          <label
                            htmlFor="start-date"
                            style={{ fontWeight: "bold" }}
                          >
                            From:
                          </label>
                          <input
                            style={{
                              marginRight: "10px",
                              padding: "10px",
                              border: "1px solid #38b6ff",
                              borderRadius: "4px",
                              width: "90%",
                            }}
                            type="datetime-local"
                            value={startDate}
                            onChange={handleStartDateChange}
                            min={new Date().toISOString().slice(0, 16)} // Allows selecting future dates
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {startDateError}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ mt: 3, minWidth: "100%" }}
                          variant="outlined"
                          m={2}
                        >
                          <label
                            htmlFor="end-date"
                            style={{ fontWeight: "bold" }}
                          >
                            To:
                          </label>
                          <input
                            style={{
                              marginRight: "10px",
                              padding: "10px",
                              border: "1px solid #38b6ff",
                              borderRadius: "4px",
                              width: "90%",
                            }}
                            type="datetime-local"
                            value={endDate}
                            onChange={handleEndDateChange}
                            min={new Date().toISOString().slice(0, 16)} // Allows selecting future dates
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {endDateError}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: "20" }}>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ mt: 3, minWidth: "100%" }}
                          variant="outlined"
                          m={2}
                          error={!!selectedCityError}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            City
                          </InputLabel>
                          <Select
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="city"
                          >
                            {cities.map((city) => (
                              <MenuItem key={city.id} value={city.id}>
                                {city.id}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{selectedCityError}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ mt: 3, minWidth: "100%" }}
                          variant="outlined"
                          m={2}
                          error={!!customerError}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Customer Type
                          </InputLabel>
                          <Select
                            value={customer}
                            onChange={(e) => setCustomer(e.target.value)}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="customer Type"
                          >
                            <MenuItem value="VIP">VIP</MenuItem>
                            <MenuItem value="NON VIP">NON VIP</MenuItem>
                            <MenuItem value="ALL">ALL</MenuItem>
                          </Select>
                          <FormHelperText>{customerError}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Button
                      sx={{
                        display: "flex",
                        minWidth: 220,
                        margin: "0 auto",
                        marginTop: "5%",
                      }}
                      type="submit"
                      ml={10}
                      variant="contained"
                      size="large"
                      color="primary"
                      mt={3}
                    >
                      Create offer
                    </Button>
                  </Box>
                </form>
              </Modal>
              <Grid sx={{ mt: 1 }}>
                <MUIDataTable
                  title="Show user details"
                  columns={columns}
                  data={ddata ? ddata : configuration?.data}
                  // data={data}
                  options={options}
                />
              </Grid>
              <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
                {formattedData?.map((item, index) => {
                  console.log("Items+++++++++++++++++++++++", item);
                  return (
                    <>
                      <div style={{ padding: 20, width: "985px" }}>
                        <Typography variant="h4" style={{ marginBottom: 10 }}>
                          Add vehicle all charges
                        </Typography>

                        <Box
                          sx={{
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            padding: "16px",
                            //   maxWidth: "800px",
                            margin: "0 auto",
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                          }}
                        >
                          <Typography variant="body1">offer details</Typography>
                          <>
                            <div>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <TextField
                                    disabled={!editMode}
                                    type="text"
                                    name="code_name"
                                    value={item.code_name}
                                    onChange={(event) =>
                                      handleChange(event, index, "code_name")
                                    }
                                    id="outlined-disabled"
                                    label="Offer code"
                                    defaultValue="Offer code"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    disabled={!editMode}
                                    type="text"
                                    id="outlined-disabled"
                                    label=""
                                    name="promo_id"
                                    value={item.promo_id}
                                    onChange={(event) =>
                                      handleChange(event, index, "promo_id")
                                    }
                                    defaultValue=""
                                  />
                                </Grid>
                              </Grid>
                            </div>

                            <div>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <TextField
                                    disabled={!editMode}
                                    type="number"
                                    id="outlined-disabled"
                                    label="value"
                                    name="discount"
                                    value={item.discount}
                                    onChange={(event) =>
                                      handleChange(event, index, "discount")
                                    }
                                    defaultValue={item.discount}
                                  />
                                </Grid>
                                <Grid item xs={3}></Grid>
                              </Grid>
                            </div>
                            <div>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <FormControl
                                    sx={{ mt: 3, minWidth: "100%" }}
                                    variant="outlined"
                                    m={2}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      customer Type
                                    </InputLabel>
                                    <Select
                                      disabled={!editMode}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      label=" customer Type"
                                      name="user_type"
                                      value={item.user_type}
                                      onChange={(event) =>
                                        handleChange(event, index, "user_type")
                                      }
                                    >
                                      <MenuItem value="VIP">{"VIP"}</MenuItem>
                                      <MenuItem value="NON VIP">
                                        {"NON VIP"}
                                      </MenuItem>
                                      <MenuItem value="ALL">{"ALL"}</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControl
                                    sx={{ mt: 3, minWidth: "100%" }}
                                    variant="outlined"
                                    m={2}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      CITY
                                    </InputLabel>
                                    <Select
                                      disabled={!editMode}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      label="CITY"
                                      name="city"
                                      value={item.city}
                                      onChange={(event) =>
                                        handleChange(event, index, "city")
                                      }
                                    >
                                      {cities.map((city) => (
                                        <MenuItem key={city.id} value={city.id}>
                                          {city.id}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </div>
                            <div>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <FormControl
                                    sx={{
                                      mt: 1,
                                      minWidth: "100%",
                                      height: "30vm",
                                    }}
                                    variant="outlined"
                                    m={2}
                                  >
                                    <label
                                      htmlFor={`start-date-${index}`}
                                      style={{ fontWeight: "bold" }}
                                    ></label>
                                    <input
                                      style={{
                                        marginRight: "10px",
                                        padding: "10px",
                                        border: "1px solid #38b6ff",
                                        borderRadius: "4px",
                                        width: "90%",
                                      }}
                                      disabled={!editMode}
                                      type="datetime-local"
                                      value={item.valid_from || ""}
                                      onChange={(e) =>
                                        handleChange(e, index, "valid_from")
                                      }
                                      min={new Date()
                                        .toISOString()
                                        .slice(0, 16)}
                                      id={`start-date-${index}`}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControl
                                    sx={{
                                      mt: 1,
                                      minWidth: "100%",
                                      height: "30vm",
                                    }}
                                    variant="outlined"
                                    m={2}
                                  >
                                    <label
                                      htmlFor={`start-date-${index}`}
                                      style={{ fontWeight: "bold" }}
                                    ></label>
                                    <input
                                      style={{
                                        marginRight: "10px",
                                        padding: "10px",
                                        border: "1px solid #38b6ff",
                                        borderRadius: "4px",
                                        width: "90%",
                                      }}
                                      disabled={!editMode}
                                      type="datetime-local"
                                      value={item.valid_to || ""}
                                      onChange={(e) =>
                                        handleChange(e, index, "valid_to")
                                      }
                                      min={new Date()
                                        .toISOString()
                                        .slice(0, 16)}
                                      id={`start-date-${index}`}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        </Box>
                      </div>
                      {editMode ? (
                        <Button
                          sx={{ m: 3, maxWidth: "70px" }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          mt={3}
                          onClick={handleUpdate}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          sx={{ m: 3, maxWidth: "70px" }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          mt={3}
                          onClick={handleEditButtonClick}
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  );
                })}
              </Drawer>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Referral Offer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "16px",
                  //   maxWidth: "800px",
                  margin: "0 auto",
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
              >
                <Typography>Referral Offer</Typography>
                <>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">From:</Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        inputProps={{ style: { height: "10px" } }}
                        disabled={!editMode1}
                        type="number"
                        id="outlined-disabled"
                        label="50"
                        defaultValue="Hello World"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">To:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        inputProps={{ style: { height: "10px" } }}
                        disabled={!editMode1}
                        type="number"
                        id="outlined-disabled"
                        label="0.3"
                        defaultValue="Hello World"
                      />
                    </Grid>
                  </Grid>
                </>
                {editMode1 ? (
                  <Button
                    sx={{ m: 3, maxWidth: "70px", marginLeft: "90%" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    // onClick={handleSubmitButtonClick1}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    sx={{ m: 3, maxWidth: "70px", marginLeft: "90%" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={handleEditButtonClick1}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default CustomerDiscount;
