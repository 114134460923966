import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  TextField,
  Stack,
  Grid,
  Box,
  Divider,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Modal,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
// redux
import { getPostData } from "../redux/action/vehicle_action";
import { useDispatch, useSelector } from "react-redux";

const empCollectionRef = collection(db, "serviceable_places");
const empCollectionRefConfig = collection(db, "configuration");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

let cod_pricing = [
  {
    cod_pricing: { "1_to_4999": "", "5000_to_20000": "" },
    distance_pricing: { "0_to_2": "", "2.1_to_10": "" },
    drop_pricing: "",
    gst: "",
    insurance: { "13000_to_20000": "", "1_to_5000": "", "5001_to_12999": "" },
    rider_commission: "",
    surgeCharge: "",
    weight_pricing: {
      "0_to_5": "",
      "10.1_to_15": "",
      "15.1_to_20": "",
      "5.1_to_10": "",
    },
  },
];

let openEditDrawerFlag = false;
const MyDataTable = () => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [city, setCity] = useState("");
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [values, setValues] = useState(cod_pricing);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cities, setCities] = useState([]);
  const [udata, setUdata] = useState([]);
  const userdata = useSelector((state) => state.post);
  const [ddata, setDdata] = useState();
  const [formattedData, setFormattedData] = useState();
  const [configuration, setConfiguration] = useState();

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  useEffect(() => {
    if (userdata.data.length) {
      setConfiguration(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]);

  const handleOpen = (item, row) => {
    for (let i = 0; i < configuration.data.length; i++) {
      if (item[0] === configuration.data[i].vehicleId) {
        return (
          setFormattedData([configuration.data[i]]),
          openEditDrawerFlag ? setOpenDrawer(true) : null
        );
      }
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
    openEditDrawerFlag = false;
  };

  const handleChange = (e, i) => {
    const updatedValue = parseInt(e.target.value);
    setFormattedData((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return { ...list, [e.target.name]: updatedValue };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleChanges = (e, i) => {
    const updatedValue = parseInt(e.target.value);
    setFormattedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[i] = {
        ...updatedData[i],
        cod_pricing: {
          ...updatedData[i].cod_pricing,
          [e.target.name]: updatedValue,
        },
      };
      return updatedData;
    });
  };
  // const DistancePricing = (e, i) => {
  //   setFormattedData((prevData) => {
  //     const updatedData = [...prevData];
  //     updatedData[i] = {
  //       ...updatedData[i],
  //       distance_pricing: {
  //         ...updatedData[i].distance_pricing,
  //         [e.target.name]: e.target.value,
  //       },
  //     };
  //     return updatedData;
  //   });
  // };

  const DistancePricing = (e, index) => {
    const updatedValue = parseInt(e.target.value);
    setFormattedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        distance_pricing: {
          ...updatedData[index].distance_pricing,
          [e.target.name]: updatedValue,
        },
      };
      return updatedData;
    });
  };
  const InsurancePricing = (e, index) => {
    const updatedValue = parseInt(e.target.value);

    setFormattedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        insurance: {
          ...updatedData[index].insurance,
          [e.target.name]: updatedValue,
        },
      };
      return updatedData;
    });
  };
  const WeightPricing = (e, index) => {
    const updatedValue = parseInt(e.target.value);

    setFormattedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        weight_pricing: {
          ...updatedData[index].weight_pricing,
          [e.target.name]: updatedValue,
        },
      };
      return updatedData;
    });
  };

  useEffect(() => {
    const configureList = () => {
      const configureDetails = async () => {
        try {
          const snapshot = await getDocs(empCollectionRef);
          return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error retrieving users:", error);
          return [];
        }
      };
      configureDetails()
        .then((users) => {
          setCities(users);
        })
        .catch((error) => console.error(error));
    };
    configureList();
  }, []);

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2",
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff",
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
    setIsSubmitted(false);
  };

  const validateInput = (inputValue) => {
    return inputValue !== "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (validateInput(city) && validateInput(vehicle)) {
      console.log("Form submitted successfully");
      handleClose1();
      setCity("");
      setVehicle("");
    } else {
      console.log("Form validation failed");
    }
  };

  const isDuplicateEntry = (city, vehicle) => {
    return data.some((data) => data[0] === city && data[1] === vehicle);
  };

  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;
      return rowData;
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          {/* <Link> */}
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => (openEditDrawerFlag = !openEditDrawerFlag)}
          >
            <EditIcon fontSize="small" />
          </Button>
          <Button
            m={1}
            variant="contained"
            size="small"
            style={{ backgroundColor: "red" }}
            onClick={(e) => {
              const rowData = tableMeta.rowData;
              let id = null;
              if (rowData) {
                id = rowData[1] + "_" + rowData[2];
              }
              console.log("delted id", id);
              handleDelete(e, id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </Button>
          {/* </Link> */}
        </Stack>
      </>
    );
  };
  const columns = [
    {
      name: "vehicleId",
      label: "ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "vehicle",
      label: "Vehicle",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];
  const options = {
    // filter: true,
    filter: false, // Hide the filter bar
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const getUsers = async () => {
    try {
      const snapshot = await getDocs(empCollectionRef);
      return snapshot.docs.map((doc) => ({ id: doc.id }));
    } catch (error) {
      console.error("Error retrieving users:", error);
      return [];
    }
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const empCollectionConfig = collection(db, "configuration");
      if (id === null) {
        return;
      }
      const docId = id;
      const docRef = doc(empCollectionConfig, docId);

      if (window.confirm("Are you sure?") != true) {
        console.log("Not deleted");
      } else {
        await deleteDoc(docRef);
        // if (userdata.data.length) {
        // setConfiguration(userdata);
        dispatch(getPostData());

        // }
        console.log("Data deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (!validateInput(city) || !validateInput(vehicle)) {
      console.log("Form validation failed");
      return;
    }

    // Parse the numeric input values
    const parsedCodPricing = {
      "1_to_4999": parseInt(values[0].cod_pricing["1_to_4999"]) || 0,
      "5000_to_20000": parseInt(values[0].cod_pricing["5000_to_20000"]) || 0,
    };

    const parsedDistancePricing = {
      "0_to_2": parseInt(values[0].distance_pricing["0_to_2"]) || 0,
      "2.1_to_10": parseInt(values[0].distance_pricing["2.1_to_10"]) || 0,
      more_then_10: parseInt(values[0].distance_pricing.more_then_10) || 0,
    };

    const parsedInsurance = {
      "13000_to_20000": parseInt(values[0].insurance["13000_to_20000"]) || 0,
      "1_to_5000": parseInt(values[0].insurance["1_to_5000"]) || 0,
      "5001_to_12999": parseInt(values[0].insurance["5001_to_12999"]) || 0,
    };

    const parsedWeightPricing = {
      "0_to_5": parseInt(values[0].weight_pricing["0_to_5"]) || 0,
      "10.1_to_15": parseInt(values[0].weight_pricing["10.1_to_15"]) || 0,
      "15.1_to_20": parseInt(values[0].weight_pricing["15.1_to_20"]) || 0,
      "5.1_to_10": parseInt(values[0].weight_pricing["5.1_to_10"]) || 0,
    };

    const parsedDropPricing = parseInt(values[0].drop_pricing) || 0;
    const parsedGst = parseInt(values[0].gst) || 0;
    const parsedRiderCommission = parseInt(values[0].rider_commission) || 0;
    const parsedSurgeCharge = parseInt(values[0].surgeCharge) || 0;

    try {
      const empCollectionConfig = collection(db, "configuration");
      const docId = `${city}_${vehicle}`;
      const docRef = doc(empCollectionConfig, docId);

      if (isDuplicateEntry(city, vehicle)) {
        console.log("Duplicate entry detected.");
        // Handle duplicate entry if needed
      } else {
        const newData = {
          vehicleId: "vehicle" + Math.ceil(Math.random() * 10000),
          city: city,
          vehicle: vehicle,
          cod_pricing: parsedCodPricing,
          distance_pricing: parsedDistancePricing,
          drop_pricing: parsedDropPricing,
          gst: parsedGst,
          insurance: parsedInsurance,
          rider_commission: parsedRiderCommission,
          surgeCharge: parsedSurgeCharge,
          weight_pricing: parsedWeightPricing,
          // Add other fields as needed
        };

        await setDoc(docRef, newData);
        dispatch(getPostData());
        console.log("Data added successfully!");
      }
    } catch (error) {
      console.error("Error adding document:", error);
    }

    handleClose1();
  };

  const handleSaveChanges = async (e, index) => {
    e.preventDefault();
    try {
      const configureDetails = async () => {
        const docId = formattedData[index].id;
        const docRef = doc(empCollectionRefConfig, docId);

        // Update the document with the modified data from the specific row
        await updateDoc(docRef, {
          cod_pricing: formattedData[index].cod_pricing,
          distance_pricing: formattedData[index].distance_pricing,
          insurance: formattedData[index].insurance,
          weight_pricing: formattedData[index].weight_pricing,
          drop_pricing: formattedData[index].drop_pricing,
          gst: formattedData[index].gst,
          rider_commission: formattedData[index].rider_commission,
          surgeCharge: formattedData[index].surgeCharge,

          // Update other fields as needed
        });

        console.log("Data updated successfully!");
      };

      configureDetails().catch((error) => {
        console.error("Error updating document:", error);
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }

    // Disable edit mode after saving changes
    setEditMode(false);
  };

  return (
    <div>
      <Button
        sx={{ mt: 3, marginLeft: "20px" }}
        onClick={handleOpen1}
        variant="contained"
        color="primary"
      >
        Add Vehicle
      </Button>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <form onSubmit={handleSave}>
          <Box sx={style}>
            <Typography variant="h6" align="left">
              Add vehicle
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose1}
            >
              <CloseIcon />
            </IconButton>

            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={12}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    City
                  </InputLabel>
                  <Select
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    error={isSubmitted && !validateInput(city)}
                    label="Select City"
                  >
                    {cities.map((items) => (
                      <MenuItem key={items.id} value={items.id}>
                        {items.id}
                      </MenuItem>
                    ))}
                  </Select>
                  {isSubmitted && !validateInput(city) && (
                    <span style={{ color: "red" }}>Please select City</span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label1">
                    Vehicle
                  </InputLabel>
                  <Select
                    value={vehicle}
                    onChange={(event) => setVehicle(event.target.value)}
                    error={isSubmitted && !validateInput(vehicle)}
                    label="Select an option"
                  >
                    <MenuItem value="Motorcycle">Motorcycle</MenuItem>
                    <MenuItem value="3Wheeler">3Wheeler</MenuItem>
                    <MenuItem value="7ft">7ft</MenuItem>
                    <MenuItem value="8ft">8ft</MenuItem>
                    <MenuItem value="Tata407">Tata407</MenuItem>
                    <MenuItem value="TataAce">TataAce</MenuItem>
                  </Select>
                  {isSubmitted && !validateInput(vehicle) && (
                    <span style={{ color: "red" }}>Please select Vehicle</span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              sx={{
                m: 3,
                minWidth: 220,
                display: "flex",
                marginTop: "20px",
                margin: "0 auto",
              }}
              type="submit"
              ml={10}
              size="large"
              color="primary"
              mt={3}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Modal>
      <Grid
        style={{
          marginTop: "0px",
          height: "100%",
          padding: "10px",
        }}
      >
        <MUIDataTable
          title="Add vehicle details"
          columns={columns}
          data={ddata ? ddata : configuration?.data}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index) => {
          console.log("Items+++++++++++++++++++++++", item);
          return (
            <>
              <div style={{ padding: 20, width: "985px" }} key={index}>
                <Typography variant="h4" style={{ marginBottom: 10 }}>
                  Add vehicle All Charges
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography>Cod_Pricing</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">1 to 4999:</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="1_to_4999"
                          value={item.cod_pricing["1_to_4999"]}
                          label={item.cod_pricing["1_to_4999"]}
                          onChange={(event) => handleChanges(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">5000 to 20000:</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="5000_to_20000"
                          value={item.cod_pricing["5000_to_20000"]}
                          label={item.cod_pricing["5000_to_20000"]}
                          defaultValue=""
                          onChange={(event) => handleChanges(event, index)}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography>Distance_Pricing</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">0 to 2 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="0_to_2"
                          value={item.distance_pricing["0_to_2"]}
                          label={item.distance_pricing["0_to_2"]}
                          defaultValue=""
                          onChange={(event) => DistancePricing(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">2.1 to 10:</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="2.1_to_10"
                          value={item.distance_pricing["2.1_to_10"]}
                          label={item.distance_pricing["2.1_to_10"]}
                          defaultValue=""
                          onChange={(event) => DistancePricing(event, index)}
                        />
                      </Grid>
                    </Grid>

                    <div>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="body1">
                            more_than_10 :
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="number"
                            name="more_then_10"
                            value={item.distance_pricing["more_then_10"]}
                            label={item.distance_pricing["more_then_10"]}
                            defaultValue=""
                            onChange={(event) => DistancePricing(event, index)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="body1">
                            Drop_pricing :
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="number"
                            name="drop_pricing"
                            value={item["drop_pricing"]}
                            label={item["drop_pricing"]}
                            defaultValue=""
                            onChange={(event) => handleChange(event, index)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="body1">Gst:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="number"
                            name="gst"
                            value={item["gst"]}
                            label={item["gst"]}
                            defaultValue=""
                            onChange={(event) => handleChange(event, index)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography>Insurance</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">
                          130000 to 20000:
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="13000_to_20000"
                          value={item.insurance["13000_to_20000"]}
                          label={item.insurance["13000_to_20000"]}
                          defaultValue=""
                          onChange={(event) => InsurancePricing(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">1 to 5000 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="1_to_5000"
                          value={item.insurance["1_to_5000"]}
                          label={item.insurance["1_to_5000"]}
                          defaultValue=""
                          onChange={(event) => InsurancePricing(event, index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">5001 to 12999 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="5001_to_12999"
                          value={item.insurance["5001_to_12999"]}
                          label={item.insurance["5001_to_12999"]}
                          defaultValue=""
                          onChange={(event) => InsurancePricing(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">
                          Rider_ commission :
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="rider_commission"
                          value={item["rider_commission"]}
                          label={item["rider_commission"]}
                          defaultValue=""
                          onChange={(event) => handleChange(event, index)}
                        />
                      </Grid>
                    </Grid>

                    <div>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="body1">Surge_charge:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            disabled={!editMode}
                            type="number"
                            name="surgeCharge"
                            value={item["surgeCharge"]}
                            label={item["surgeCharge"]}
                            defaultValue=""
                            onChange={(event) => handleChange(event, index)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography>weight_pricing</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">0 to 5 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="0_to_5"
                          value={item.weight_pricing["0_to_5"]}
                          label={item.weight_pricing["0_to_5"]}
                          defaultValue=""
                          onChange={(event) => WeightPricing(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">10.1 to 15 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="10.1_to_15"
                          value={item.weight_pricing["10.1_to_15"]}
                          label={item.weight_pricing["10.1_to_15"]}
                          defaultValue=""
                          onChange={(event) => WeightPricing(event, index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">15.1 to 20 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="15.1_to_20"
                          value={item.weight_pricing["15.1_to_20"]}
                          label={item.weight_pricing["15.1_to_20"]}
                          defaultValue=""
                          onChange={(event) => WeightPricing(event, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">5.1 to 10 :</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          disabled={!editMode}
                          type="number"
                          name="5.1_to_10"
                          value={item.weight_pricing["5.1_to_10"]}
                          label={item.weight_pricing["5.1_to_10"]}
                          defaultValue=""
                          onChange={(event) => WeightPricing(event, index)}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Box>
                {editMode ? (
                  <Button
                    variant="contained"
                    onClick={(e) => handleSaveChanges(e, index)}
                    sx={{
                      m: 3,
                      display: "flex",
                      marginTop: "20px",
                      margin: "0 auto",
                      minWidth: 220,
                    }}
                    size="large"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleEditButtonClick}
                    sx={{
                      m: 3,
                      display: "flex",
                      marginTop: "20px",
                      margin: "0 auto",
                      minWidth: 220,
                    }}
                    size="large"
                    color="primary"
                  >
                    Edit
                  </Button>
                )}
              </div>
            </>
          );
        })}
      </Drawer>
    </div>
  );
};

export default MyDataTable;
