import React from "react";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import { Box } from "@mui/material";
import Editcustomer from "../customer/Editcustomer";
import { useLocation } from "react-router-dom";

const Editcustomers = () => {
  const dataMain = useLocation();
  console.log("main data", dataMain);
  // const {from }= dataMain.state ;

  // console.log(from , "+++----");
  return (
    <div>
      {" "}
      <>
        <div className="bgcolor">
          <Navbar />
          <Box height={70} />
          <Box sx={{ display: "flex" }}>
            <Sidenav />
            <Box component="main" sx={{ flexGrow: 1, p: 0.3 }}></Box>
            <Editcustomer dataMain={dataMain} />
          </Box>
        </div>
      </>
    </div>
  );
};

export default Editcustomers;
