import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, updateCurrentUser, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase-config";

const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState({});
    function Register(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    function Login(email, password) {
        console.log("Email", email)
        return signInWithEmailAndPassword(auth, email, password)
    }
    const forgotPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            // console.log(currentuser);
            setUser(currentuser);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    return (
        <userAuthContext.Provider value={{ user, Register, Login, forgotPassword }}>
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext)
}
