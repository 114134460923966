import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import CustomerIssues from "./CustomerIssues";
import RiderIssues from "./RiderIssues";

const Issues = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
        sx={{ borderRadius: "10px" }}
      >
        <Tab
          sx={{
            background: selectedTab === 0 ? "#1976D2" : "#38b6ff",
            width: "100%",
          }}
          label="Customer Issues"
          style={{
            color: selectedTab === 0 ? "white" : "black",
            fontWeight: selectedTab === 0 ? "bold" : "normal",
          }}
        />
        <Tab
          sx={{
            background: selectedTab === 1 ? "#1976D2" : "#38b6ff",
            width: "100%",
          }}
          label="Rider Issues"
          style={{
            color: selectedTab === 1 ? "white" : "black",
            fontWeight: selectedTab === 1 ? "bold" : "normal",
          }}
        />
      </Tabs>

      {selectedTab === 0 && (
        <div>
          <CustomerIssues />
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          <RiderIssues />
        </div>
      )}
    </div>
  );
};

export default Issues;
