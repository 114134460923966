import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostData } from "../redux/action/action";
import { Box } from "@mui/material";

const TrackOrder = (props) => {
  const dispatch = useDispatch();
  const orderId = props.dataMain;
  const orderData = useSelector((state) => state.post);
  const [location, setLocation] = useState({
    _lat: 0,
    _long: 0,
  });
  const [dropLocations, setDropLocations] = useState();
  const [pickLocations, setPickLocations] = useState();
  const [coords, setCoords] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    dispatch(getPostData());
    setCoords(true); // Assuming you want to set coords to true immediately
  }, [dispatch]);

  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDB6W0oPY2YmJJMiT_ihxT1jJ360xT8Wlw&libraries=places`;
    script.async = true;
    script.onload = () => {
      setMapLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  };

  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    const filteredOrder = orderData.data.find(
      (order) => order.orderId === orderId
    );
    if (filteredOrder) {
      const assignedRiders = filteredOrder.assignedRider;
      const dropOffs = filteredOrder.dropLocations;
      const pickOffs = filteredOrder.pickUpLocation;

      if (
        assignedRiders &&
        assignedRiders.length > 0 &&
        assignedRiders[0].currentLocation &&
        typeof assignedRiders[0].currentLocation._lat === "number" &&
        typeof assignedRiders[0].currentLocation._long === "number"
      ) {
        const { _lat, _long } = assignedRiders[0].currentLocation;
        setLocation({ _lat, _long });
      }

      if (
        dropOffs &&
        dropOffs.length > 0 &&
        dropOffs[0].geoCode &&
        typeof dropOffs[0].geoCode._lat === "number" &&
        typeof dropOffs[0].geoCode._long === "number"
      ) {
        const { _lat, _long } = dropOffs[0].geoCode;
        setDropLocations({ _lat, _long });
        setCoords(true);
      }

      if (
        pickOffs &&
        pickOffs.length > 0 &&
        pickOffs[0].geoCode &&
        typeof pickOffs[0].geoCode._lat === "number" &&
        typeof pickOffs[0].geoCode._long === "number"
      ) {
        const { _lat, _long } = pickOffs[0].geoCode; // Fix the typo here
        setPickLocations({ _lat, _long });
        setCoords(true);
      }
    }
  }, [orderData, orderId]);

  useEffect(() => {
    if (mapLoaded && coords) {
      initMap();
    }
  }, [mapLoaded, coords]);

  // ... (previous code remains the same)

  const initMap = () => {
    const mapElement = document.getElementById("map");
    if (mapElement) {
      const map = new window.google.maps.Map(mapElement, {
        center: { lat: location._lat, lng: location._long },
        zoom: 15,
      });

      const assignedRiders =
        orderData.data.find((order) => order.orderId === orderId)
          ?.assignedRider || [];
      const dropOffs =
        orderData.data.find((order) => order.orderId === orderId)
          ?.dropLocations || [];
      const pickOffs =
        orderData.data.find((order) => order.orderId === orderId)
          ?.pickUpLocation || {};

      // Marker for current location
      const assignedRiderMarkers = [];
      const assignedRiderCoordinates = [];

      // Marker for current location of assigned riders (red markers)
      assignedRiders.forEach((rider, index) => {
        if (
          rider.currentLocation &&
          typeof rider.currentLocation._lat === "number" &&
          typeof rider.currentLocation._long === "number"
        ) {
          const riderMarker = new window.google.maps.Marker({
            position: {
              lat: rider.currentLocation._lat,
              lng: rider.currentLocation._long,
            },
            map: map,
            title: `Rider Location ${index + 1} for Order ${orderId}`,
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Change to red marker
              scaledSize: new window.google.maps.Size(40, 40),
            },
          });
          assignedRiderMarkers.push(riderMarker);

          assignedRiderCoordinates.push({
            lat: rider.currentLocation._lat,
            lng: rider.currentLocation._long,
          });
        }
      });

      // Marker for drop-off locations
      const dropOffMarkers = [];
      const pathCoordinates = [];

      dropOffs.forEach((dropOff, index) => {
        if (
          dropOff &&
          dropOff.geoCode &&
          typeof dropOff.geoCode._lat === "number" &&
          typeof dropOff.geoCode._long === "number"
        ) {
          const dropOffMarker = new window.google.maps.Marker({
            position: {
              lat: dropOff.geoCode._lat,
              lng: dropOff.geoCode._long,
            },
            map: map,
            title: `Drop-off Location ${index + 1} for Order ${orderId}`,
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              scaledSize: new window.google.maps.Size(40, 40),
            },
          });
          dropOffMarkers.push(dropOffMarker);

          pathCoordinates.push({
            lat: dropOff.geoCode._lat,
            lng: dropOff.geoCode._long,
          });
        }
      });

      const pickOffMarkers = [];
      const pickCoordinates = [];

      if (
        pickOffs &&
        pickOffs.geoCode &&
        typeof pickOffs.geoCode._lat === "number" &&
        typeof pickOffs.geoCode._long === "number"
      ) {
        const pickOffMarker = new window.google.maps.Marker({
          position: {
            lat: pickOffs.geoCode._lat,
            lng: pickOffs.geoCode._long,
          },
          map: map,
          title: `Pick-off Location for Order ${orderId}`,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });
        pickOffMarkers.push(pickOffMarker);

        pickCoordinates.push({
          lat: pickOffs.geoCode._lat,
          lng: pickOffs.geoCode._long,
        });
      }

      // Draw route from assignedRiders to pickOffs to dropOffs
      if (assignedRiderCoordinates.length > 0 && pickCoordinates.length > 0) {
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);

        const waypointsPick = assignedRiderCoordinates.map((rider, index) => ({
          location: new window.google.maps.LatLng(rider.lat, rider.lng),
          stopover: true,
        }));

        const requestPick = {
          origin: new window.google.maps.LatLng(
            assignedRiderCoordinates[0].lat,
            assignedRiderCoordinates[0].lng
          ),
          waypoints: waypointsPick,
          destination: new window.google.maps.LatLng(
            pickCoordinates[0].lat,
            pickCoordinates[0].lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        if (dropOffMarkers.length > 0) {
          // Draw route from pick-offs to drop-offs if drop-offs are available
          const waypointsDrop = dropOffMarkers.map((dropOffMarker, index) => ({
            location: dropOffMarker.getPosition(),
            stopover: true,
          }));

          const requestDrop = {
            origin: new window.google.maps.LatLng(
              pickCoordinates[0].lat,
              pickCoordinates[0].lng
            ),
            waypoints: waypointsDrop,
            destination: new window.google.maps.LatLng(
              dropOffMarkers[0].getPosition().lat(),
              dropOffMarkers[0].getPosition().lng()
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          };

          // Request for the route from assignedRiders to pick-offs
          directionsService.route(requestPick, (resultPick, statusPick) => {
            if (statusPick === window.google.maps.DirectionsStatus.OK) {
              // Display the route on the map
              directionsRenderer.setDirections(resultPick);

              // Request for the route from pick-offs to drop-offs
              directionsService.route(requestDrop, (resultDrop, statusDrop) => {
                if (statusDrop === window.google.maps.DirectionsStatus.OK) {
                  // Display the additional route on the map
                  directionsRenderer.setDirections(resultDrop);
                } else {
                  console.error(
                    `Directions request failed due to ${statusDrop}`
                  );
                }
              });
            } else {
              console.error(`Directions request failed due to ${statusPick}`);
            }
          });
        } else {
          // Draw route from assigned riders to pick-offs if no drop-offs are available
          directionsService.route(requestPick, (resultPick, statusPick) => {
            if (statusPick === window.google.maps.DirectionsStatus.OK) {
              // Display the route on the map
              directionsRenderer.setDirections(resultPick);
            } else {
              console.error(`Directions request failed due to ${statusPick}`);
            }
          });
        }
      }
    }
  };

  return (
    <div>
      <h1>Tracking Order {orderId}</h1>
      {coords ? (
        <div id="map" style={{ width: "100%", height: "400px" }}></div>
      ) : (
        <Box>No Location</Box>
      )}
    </div>
  );
};

export default TrackOrder;
