import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";

export const getPostData = createAsyncThunk(
  "posts/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "ticketData"),
        where("userType", "==", "Rider")
      );
      const data = await getDocs(q);
      const mydata = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      return mydata;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
