import React from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import Editbulk from "../Bulkorder/Editbulk";

const EditbulkOrder = () => {
  const dataMain = useLocation();
  console.log("main data", dataMain);
  // const {from }= dataMain.state ;

  // console.log(from , "+++----");
  return (
    <>
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 0.3 }}>
            <Editbulk dataMain={dataMain} />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default EditbulkOrder;
