import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Card,
  FormGroup,
  Divider,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const pointerStyle = {
  cursor: "pointer",
};
const paperStyle = {
  backgroundColor: "#38b6ff", // Default color
};

const paperHoverStyle = {
  backgroundColor: "#1976D2", // Hover color
};

export default function Addmap() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <React.Fragment>
      <div style={{ padding: "10px" }}>
        <Grid container spacing={3} style={pointerStyle}>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("/addcitymap");
              }}
              style={
                isHovered ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>Service Area Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("/vehicle");
              }}
              style={
                isHovered1 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>vehicle Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("/usermanagement");
              }}
              style={
                isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>User Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("");
              }}
              style={
                isHovered3 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered3(true)}
              onMouseLeave={() => setIsHovered3(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>Account Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("/offer");
              }}
              style={
                isHovered4 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered4(true)}
              onMouseLeave={() => setIsHovered4(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>Offer Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} lg={4} xl={2}>
            <Paper
              onClick={() => {
                navigate("/notification");
              }}
              style={
                isHovered5 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
              }
              onMouseEnter={() => setIsHovered5(true)}
              onMouseLeave={() => setIsHovered5(false)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Box
                    borderRadius="50%"
                    width="30px"
                    height="60px"
                    style={{ display: "flex", marginLeft: "10px" }}
                  ></Box>
                </div>
                <div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                  ></Typography>
                  <Typography style={{ color: "black" }}>
                    <Box fontSize={12}>Notification Management</Box>
                  </Typography>
                </div>
                <div />
              </div>
            </Paper>
          </Grid>
        </Grid>
        {/* <Button onClick={handleOpen}>Add new vehicle</Button>
      <Button onClick={handleOpen}>Add new vehicle</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={style}>
            <>
              <Box sx={{ m: 2 }} />
              <Typography variant="h5" align="center">
                Add new vahicle
              </Typography>
              <Divider sx={{ mt: 2 }} />
              <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={false}
                    id="name"
                    name="Name of vahicle"
                    label="Name of vahicle"
                    size="small"
                    sx={{ marginTop: "30px", minWidth: "100%" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={false}
                    id="city"
                    label="city"
                    select
                    size="small"
                    sx={{ minWidth: "100%" }}
                  >
                    {/* {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))} */}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    <Button variant="contained">Submit</Button>
                  </Typography>
                </Grid>
              </Grid>
            </>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h6" align="left">
              User Management
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  name="name"
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="Email"
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Phone"
                  type="number"
                  name="number"
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="password"
                  name="password"
                  type="password"
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                {/* <TextField
                label="User Type"
                name="type"
                value="Super Admin"
                select
                sx={{ marginTop: "30px", minWidth: "100%" }}
                variant="outlined"
              >
                {utypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            &nbsp;&nbsp;{option.label}
                          </option>
                        ))}
              </TextField> */}
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    required={true}
                    name={"order_status"}
                    label="User Type"
                  >
                    <MenuItem value={10}>Super Admin</MenuItem>
                    <MenuItem value={20}>Admin</MenuItem>
                    <MenuItem value={30}>Manager</MenuItem>
                    <MenuItem value={30}>User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FormGroup sx={{ flexDirection: "row" }}>
              <FormControlLabel control={<Checkbox />} label="order list" />
              <FormControlLabel control={<Checkbox />} label="Rider list" />
              <FormControlLabel control={<Checkbox />} label="Customer list" />
              <FormControlLabel control={<Checkbox />} label="Issue ticket" />
              <FormControlLabel control={<Checkbox />} label="Setting" />
            </FormGroup>
            <Button
              sx={{ m: 3, minWidth: 220 }}
              type="submit"
              ml={10}
              variant="contained"
              size="large"
              color="primary"
              mt={3}
            >
              Update
            </Button>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  );
}
