import React from "react";
import { AddCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Checkbox,
  ListItem,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// import helmet from "helmet";

const Users = () => {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Create New User
              </Typography>
              <Divider my={6} />
              <form autoComplete="off">
                <TextField
                  sx={{ m: 3, minWidth: 220 }}
                  id="standard"
                  label="Name"
                  name="Name"
                  // value={taskData.task_remark}
                  // onChange={addTaskData}
                  variant="outlined"
                  m={2}
                />
                <TextField
                  sx={{ m: 3, minWidth: 220 }}
                  id="standard"
                  label="Email"
                  name="Email"
                  // value={taskData.task_remark}
                  // onChange={addTaskData}
                  variant="outlined"
                  m={2}
                />
                <TextField
                  sx={{ m: 3, minWidth: 220 }}
                  id="standard"
                  label="Password"
                  name="Password"
                  // value={taskData.task_remark}
                  // onChange={addTaskData}
                  variant="outlined"
                  m={2}
                />
                <FormControl
                  variant="outlined"
                  m={2}
                  sx={{ m: 3, minWidth: 220 }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    required={true}
                    name={"Select role"}
                    label="Select role"
                  >
                    {/* {Object.keys(TaskStatus).map(function (key, index) {
                                                return <MenuItem value={key}>{key}</MenuItem>;
                                            })} */}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ m: 3, minWidth: 220 }}
                  id="standard"
                  label="Phone"
                  name="Phone"
                  type="number"
                  // value={taskData.task_remark}
                  // onChange={addTaskData}
                  variant="outlined"
                  m={2}
                />
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}
                  role="group"
                  aria-labelledby="sandwich-group"
                >
                  <List size="sm">
                    <Checkbox label="Primary" color="primary" defaultChecked />
                    <Checkbox label="Danger" name="Checkbox 5" defaultChecked />
                    <Checkbox label="Info" defaultChecked />
                  </List>
                </Box>
                <Divider my={6} />
                <Button
                  type="submit"
                  ml={10}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Create new user
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Users;
