import { AddCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextareaAutosize,
  NativeSelect,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostData } from "../redux/action/rider_action";
import {
  collection,
  getDoc,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import {
  validateAccountNum,
  validateAlphabetsOnly,
  validateNumariAndCapital,
} from "../common/validation";
const empCollectionRef = collection(db, "ridersData");

const Editrider = (props) => {
  const [isApproved, setIsApproved] = useState(false);
  const [isApproved2, setIsApproved2] = useState(false);
  const [approvalStates, setApprovalStates] = useState([]);
  const [showTextbox, setShowTextbox] = useState(false);
  const [showTextbox2, setShowTextbox2] = useState([]);
  const [showTextboxReject, setShowTextBoxReject] = useState(false);
  const EditData = useSelector((state) => state.post);
  const [edata, setEdata] = useState();
  const [udata, setUdata] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [showApprove, setShowApprove] = useState(true);
  const [showSuspend, setShowSuspend] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [reasonMsg, setReasonMsg] = useState("");

  const handleReasonSubmit = async (index, reason) => {
    try {
      const docId = udata[0].id;
      const empDocRef = doc(empCollectionRef, docId);

      const updatedWithdrawalRequests = udata[0].withdrawalRequest.map(
        (request, i) => {
          if (i === index) {
            return {
              ...request,
              status: "rejected", // Update the status to 'rejected'
              reason: reason, // Update the reason
            };
          }
          return request;
        }
      );

      const updateData = {
        ...udata[0],
        withdrawalRequest: updatedWithdrawalRequests,
      };

      await updateDoc(empDocRef, updateData);

      // Dispatch an action to update Redux store
      dispatch(getPostData());

      console.log("Data updated successfully!");
      localStorage.setItem("accountStatus", "rejected");

      // Optionally, you can show an alert or provide user feedback here

      // Hide the textarea after submission
      setShowTextbox2((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = false;
        return newStates;
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    const accountStatus = localStorage.getItem("accountStatus");
    console.log("accountStatus:", accountStatus); // Check the value of accountStatus

    if (accountStatus === "suspended") {
      console.log("I am suspended");
      setShowApprove(false);
      setShowSuspend(false);
      setShowReset(true);
    } else if (accountStatus === "applied" || accountStatus === "pending") {
      console.log("I am applied or pending");
      setShowApprove(true);
      setShowSuspend(false);
      setShowReset(false);
    } else if (accountStatus === "verified") {
      console.log("I am verified");
      setShowReset(false);
      setShowApprove(false);
      setShowSuspend(true);
    } else if (accountStatus === "rejected") {
      console.log("I am rejected");
      setShowApprove(false);
      setShowSuspend(false);
    } else {
      // Handle the case when accountStatus is undefined or other values
      console.log("Unknown accountStatus:", accountStatus);
      // You can choose to set some default values here if needed
    }
  }, []);

  const dispatch = useDispatch();
  // useEffect(()=>{
  // const manishData =
  // },[])

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  // useEffect(() =>{
  //   let updatedData = "applied"
  //   localStorage.setItem('accountStatus', updatedData);
  // }, [])

  // useEffect(()=>{
  //   const getStatus = async () => {
  //     try {
  //       const snapshot = await getDocs(empCollectionRef);
  //       return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     } catch (error) {
  //       console.error("Error retrieving users:", error);
  //       return [];
  //     }
  //   };

  //   getStatus()
  //     .then((users) => {
  //       // console.log(users, "bhxsbhsxbhsbxhsbxhsbxhsbxhsbhs");
  //       let accountSTatus = users[0].accountStatus;
  //       console.log("accountSTatus", accountSTatus)
  //       if (accountSTatus === "suspended" || accountSTatus === "applied") {
  //         setShowApprove(true);
  //         setShowSuspend(false);
  //       } else if (accountSTatus === "verified") {
  //         setShowSuspend(true);
  //         setShowApprove(false);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // },[])

  useEffect(() => {
    const getUsersData = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsersData()
      .then((users) => {
        // console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          console.log("docId", docId);
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }
          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            // ...udata[0], // Use the desired array element or properties here
            accountStatus: "applied",
          };
          setShowApprove(true);
          setShowSuspend(false);
          updateDoc(empDocRef, updateData)
            .then(() => {
              const { accountStatus } = updateData;
              localStorage.setItem("accountStatus", accountStatus);
              console.log(
                "Data updated successfully and stored in localStorage:",
                accountStatus
              );
              setShowApprove(true);
              setShowSuspend(false);
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  }, []);

  console.log("showApprove:", showApprove); // Add this line
  console.log("showSuspend:", showSuspend); // Add this line

  useEffect(() => {
    if (EditData.data.length) {
      setEdata(EditData);
    } else {
      console.log("Data is not available");
    }
  }, [EditData]);

  // const data1 = [edata];
  console.log(edata?.data, "00000000");
  console.log(props.dataMain.state.from[0], "+++----0000");

  useEffect(() => {
    for (let i = 0; i < edata?.data.length; i++) {
      if (props.dataMain.state.from[0] === edata?.data[i].riderId) {
        return setUdata([edata?.data[i]]);
      }
    }
  }, [edata?.data, props.dataMain.state.from[0]]);

  console.log(udata, "@@@@@123");

  const handleChange = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return { ...list, [e.target.name]: e.target.value };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleChanges = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return {
            ...list,
            bankAccount: {
              ...list.bankAccount,
              [e.target.name]: e.target.value,
            },
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  console.log(udata, "@@@@@123");

  //localStorage

  const handleReset = (e) => {
    setShowReset(e.target.value);
    setShowApprove(true);
    setShowSuspend(false);

    const getUsersData = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsersData()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id;
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            accountStatus: "applied",
          };
          setShowApprove(true);
          setShowSuspend(false);
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
              localStorage.setItem("accountStatus", updateData.accountStatus); // Store in localStorage here
              setShowApprove(true);
              setShowSuspend(false);
              window.alert("Account status Reset Successfully.");

              // Refresh the page when the user clicks OK in the alert
              setTimeout(() => {
                window.location.reload();
              }, 100); // Refresh the page after a short delay (100 milliseconds)
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };
  //Approve button

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsers()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            ...udata[0], // Use the desired array element or properties here
          };
          alert("Succesfully Updated!!!");
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };
  const [validationObject, setvalidationObject] = useState({
    accountNumber: {
      error: false,
      errMessage: "",
    },
    accountName: {
      error: false,
      errMessage: "",
    },
    pan: {
      error: false,
      errMessage: "",
    },
    ifscCode: {
      error: false,
      errMessage: "",
    },
    bankName: {
      error: false,
      errMessage: "",
    },
  });

  const validateAllField = (name, event) => {
    switch (name) {
      case "accountNumber":
        validationObject[event.target.name] = validateAccountNum(event);
        break;
      case "accountName":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "bankName":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "pan":
        validationObject[event.target.name] = validateNumariAndCapital(event);
        break;
      case "ifscCode":
        validationObject[event.target.name] = validateNumariAndCapital(event);
        break;
    }
  };

  const handleApprove = () => {
    // window.alert("Account status applied successfully");
    setShowSuspend(true);
    setShowApprove(false);
    setShowReset(false);
    const getUsersData = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsersData()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id;
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }
          const empDocRef = doc(empCollectionRef, docId);
          const updateData = {
            accountStatus: "verified",
          };
          // localStorage.setItem("accountStatus", updateData.accountStatus);
          setShowApprove(false);
          setShowSuspend(true);
          setShowReset(false);
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
              localStorage.setItem("accountStatus", updateData.accountStatus); // Store in localStorage here
              setShowApprove(false);
              setShowSuspend(true);
              setShowReset(false);
              window.alert("Account status Verified successfully.");

              // Refresh the page when the user clicks OK in the alert
              setTimeout(() => {
                window.location.reload();
              }, 100); // Refresh the page after a short delay (100 milliseconds)
            })

            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSuspend = () => {
    setShowApprove(false);
    setShowSuspend(false);
    setShowReset(true);
    setShowTextbox(false);
    const getUsersData = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsersData()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id;
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          if (reasonMsg !== "") {
            const updateData = {
              reason: reasonMsg,
              accountStatus: "suspended",
            };
            setShowApprove(false);
            setShowSuspend(false);
            setShowReset(true);
            updateDoc(empDocRef, updateData)
              .then(() => {
                console.log("Data updated successfully!");
                localStorage.setItem("accountStatus", updateData.accountStatus); // Store in localStorage here
                setShowApprove(false);
                setShowSuspend(false);
                setShowReset(true);
                window.alert("Account status Suspended successfully.");

                // Refresh the page when the user clicks OK in the alert
                setTimeout(() => {
                  window.location.reload();
                }, 100); // Refresh the page after a short delay (100 milliseconds)
              })
              .catch((error) => {
                console.error("Error updating document:", error);
              });
          }
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));

    setShowApprove(false);
    setShowSuspend(false);
    setShowReset(true);
  };

  const handleReject = () => {
    // setShowApprove(true);
    const getUsersData = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsersData()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id;
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          if (reasonMsg !== "") {
            const updateData = {
              reason: reasonMsg,
              accountStatus: "rejected",
            };
            // setShowApprove(true);
            setShowTextBoxReject(false);

            updateDoc(empDocRef, updateData)
              .then(() => {
                console.log("Data updated successfully!");
                localStorage.setItem("accountStatus", updateData.accountStatus); // Store in localStorage here
                // setShowApprove(true);
                window.alert("Account status Rejected successfully.");

                // Refresh the page when the user clicks OK in the alert
                setTimeout(() => {
                  window.location.reload();
                }, 100); // Refresh the page after a short delay (100 milliseconds)
              })
              .catch((error) => {
                console.error("Error updating document:", error);
              });
          }
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));

    setShowApprove(false);
    setShowSuspend(false);
    setShowApprove(false);
    setShowSuspend(false);
    setShowReset(false);
  };

  const handleDisapprove = () => {
    setShowTextbox(true);
  };
  const handleDisapproveReject = () => {
    setShowTextBoxReject(true);
  };

  const handleReasonChange = (e) => {
    setReasonMsg(e.target.value);
  };

  const handleApprove2 = async (index) => {
    try {
      const docId = udata[0].id;
      const empDocRef = doc(empCollectionRef, docId);

      const updatedWithdrawalRequests = udata[0].withdrawalRequest.map(
        (request, i) => {
          if (i === index) {
            return {
              ...request,
              status: "verified", // Update the status to 'verified'
            };
          }
          return request;
        }
      );

      const updateData = {
        ...udata[0],
        withdrawalRequest: updatedWithdrawalRequests,
      };

      await updateDoc(empDocRef, updateData);

      // Dispatch an action to update Redux store
      dispatch(getPostData());

      console.log("Data updated successfully!");
      localStorage.setItem("accountStatus", "verified");

      // Optionally, you can show an alert or provide user feedback here
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  // ...

  const handleDisapprove2 = (index) => {
    setShowTextbox2((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true; // Set the approval state to true for the clicked card
      return newStates;
    });
  };

  // const checkImagesExist=()=>{
  //   return null}
  const checkImagesExist = () => {
    const accountStat = localStorage.getItem("accountStatus");

    const accountStatus = udata[0].accountStatus;
    console.log("ndjnjdcn____--", accountStatus);
    if (
      accountStatus === "applied" ||
      accountStatus === "suspended" ||
      accountStat === "applied" ||
      accountStat === "verified"
      // accountStat === "rejected"
    ) {
      const { documents } = udata[0];
      const { vehicle } = udata[0];
      // console.log("documents ++++++++++++", documents)
      // Check if all required images exist
      return (
        documents &&
        documents.aadharCard &&
        documents.aadharCardBack &&
        documents.selfie &&
        vehicle.numberPlatePhotUrl &&
        vehicle.vehicleRCPhotoUrl
      );
    }
    // else if (accountStatus === "verified"){
    //     setShowApprove(false)
    //     setShowSuspend(true)
    // }
    //   else if (accountStatus === "suspended"){
    //     setShowApprove(true)
    //     setShowSuspend(false)
    //   }
    else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        {udata?.map((item, index) => {
          return (
            <Grid item xs={12}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Edit Rider details
                  </Typography>
                  <Paper mt={9}>
                    <form autoComplete="off" key={index}>
                      <FormControl
                        sx={{
                          m: 3,
                          minWidth: 220,
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Employment Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name={"employmentType"}
                          value={item.employmentType}
                          onChange={(e) => handleChange(e, index)}
                          label="Employment Type"
                        >
                          <MenuItem value="Full Time">{"Full Time"}</MenuItem>
                          <MenuItem value="Part Time">{"Part Time"}</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Working City"
                        name="workingCity"
                        required={true}
                        value={item.workingCity}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        multiline
                        label="Working Location"
                        name="workingLocation"
                        value={item.workingLocation}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        // required
                        id="standard"
                        multiline
                        label="Address"
                        name="address"
                        value={item.address}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />
                      <Divider my={6} />
                      <Typography variant="h6">Bank Details</Typography>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Account Name"
                        name="accountName"
                        value={item.bankAccount.accountName}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("accountName", event);
                        }}
                        variant="outlined"
                        error={validationObject.accountName?.error}
                        helperText={validationObject.accountName?.errMessage}
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Account Number"
                        // required={true}
                        name="accountNumber"
                        value={item.bankAccount.accountNumber}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("accountNumber", event);
                        }}
                        variant="outlined"
                        error={validationObject.accountNumber?.error}
                        helperText={validationObject.accountNumber?.errMessage}
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Bank Name"
                        name="bankName"
                        value={item.bankAccount.bankName}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("bankName", event);
                        }}
                        variant="outlined"
                        error={validationObject.bankName?.error}
                        helperText={validationObject.bankName?.errMessage}
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="IFSC CODE"
                        name="ifscCode"
                        value={item.bankAccount.ifscCode}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("ifscCode", event);
                        }}
                        variant="outlined"
                        error={validationObject.ifscCode?.error}
                        helperText={validationObject.ifscCode?.errMessage}
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="PAN"
                        // required={true}
                        name="pan"
                        value={item.bankAccount.pan}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("pan", event);
                        }}
                        variant="outlined"
                        error={validationObject.pan?.error}
                        helperText={validationObject.pan?.errMessage}
                        m={2}
                      />

                      <Divider my={6} />
                      <Button
                        sx={{ m: 3, minWidth: 220 }}
                        type="submit"
                        ml={10}
                        variant="contained"
                        size="large"
                        color="primary"
                        mt={3}
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                    </form>
                  </Paper>
                  <Typography variant="h5" gutterBottom>
                    Rider Document details
                  </Typography>
                  <TableContainer
                    component={Paper}
                    style={{ padding: "10px 60px", marginBottom: "20px" }}
                  >
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            Selfie
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.documents.selfie}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            AadharCard
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.documents.aadharCard}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            AadharCard back
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.documents.aadharCardBack}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            ElectricityBill
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.documents.electricityBill}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            NumberPlatePhotUrl
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.vehicle.numberPlatePhotUrl}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell width="20%" align="left">
                            VehicleRCPhotoUrl
                          </TableCell>
                          <TableCell align="left">
                            <img
                              src={item.vehicle.vehicleRCPhotoUrl}
                              width="500vh"
                              height="300vh"
                              alt="Pending"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      {["applied", "rejected"].includes(item.accountStatus) && (
                        <>
                          <Button
                            onClick={handleApprove}
                            // disabled={isApproved}
                            style={{
                              backgroundColor: isApproved ? "green" : "primary",
                              display: checkImagesExist() ? "block" : "none",
                            }}
                            sx={{ m: 3, minWidth: 220 }}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            mt={3}
                          >
                            Approved
                          </Button>
                          <Button
                            onClick={handleDisapproveReject}
                            // disabled={showTextbox}
                            style={{
                              display: checkImagesExist() ? "block" : "none",
                            }}
                            sx={{ m: 3, minWidth: 220, marginLeft: "15%" }}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            mt={3}
                          >
                            DisApproved
                          </Button>
                        </>
                      )}

                      {item.accountStatus === "verified" && (
                        <Button
                          onClick={handleDisapprove}
                          // disabled={showTextbox}
                          sx={{ m: 3, minWidth: 220 }}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="error"
                          mt={3}
                        >
                          Suspend
                        </Button>
                      )}

                      {item.accountStatus === "suspended" && (
                        <Button
                          onClick={handleReset}
                          // disabled={showTextbox}
                          sx={{ m: 3, minWidth: 220 }}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="error"
                          mt={3}
                        >
                          Reset
                        </Button>
                      )}

                      {showTextbox && (
                        <div>
                          <div
                            style={{
                              marginLeft: "auto",
                              marginTop: "25px",
                              display: "flex",
                            }}
                          >
                            <TextareaAutosize
                              sx={{
                                ml: 3,
                                minWidth: 250,
                              }}
                              rowsMin={4}
                              value={reasonMsg}
                              onChange={handleReasonChange}
                              placeholder="Enter your reason for disapproval"
                            />
                            <Button
                              sx={{ minWidth: 100 }}
                              type="submit"
                              ml={10}
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={handleSuspend}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      )}

                      {showTextboxReject && (
                        <div>
                          <div
                            style={{
                              marginLeft: "auto",
                              marginTop: "25px",
                              display: "flex",
                            }}
                          >
                            <TextareaAutosize
                              sx={{
                                ml: 3,
                                minWidth: 250,
                              }}
                              rowsMin={4}
                              value={reasonMsg}
                              onChange={handleReasonChange}
                              placeholder="Enter your reason for disapproval"
                            />
                            <Button
                              sx={{ minWidth: 100 }}
                              type="submit"
                              ml={10}
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={handleReject}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </TableContainer>
                  <Typography variant="h5" gutterBottom>
                    Withdrawal Request
                  </Typography>
                  {/* ****************************withdrawalRequest details ******************** */}
                  <div>
                    {item.withdrawalRequest.map((data, index) => (
                      <Card
                        style={{ padding: 15, marginTop: 10, flex: 1 }}
                        key={index}
                      >
                        <Typography>
                          <strong>Withdrawal Request :{index + 1}</strong>
                        </Typography>
                        <Typography sx={{ mt: 3 }}>
                          <Typography sx={{ display: "flex", marginLeft: 10 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  <strong>amount:</strong>
                                  {data.amount}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  <strong>reason:</strong>
                                  {data.reason}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography sx={{ display: "flex", marginLeft: 10 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  <strong>status:</strong> {data.status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Button
                            onClick={() => handleApprove2(index)}
                            disabled={data.status === "verified"} // Disable if the status is 'verified'
                            sx={{ m: 3, minWidth: 220 }}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            mt={3}
                          >
                            Approved
                          </Button>
                          <Button
                            onClick={() => handleDisapprove2(index)}
                            disabled={data.status === "rejected"}
                            sx={{ m: 3, minWidth: 220, marginLeft: "15%" }}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            mt={3}
                          >
                            DisApproved
                          </Button>
                          {showTextbox2[index] && (
                            <div>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginTop: "25px",
                                  display: "flex",
                                }}
                              >
                                <TextareaAutosize
                                  sx={{
                                    ml: 3,
                                    minWidth: 250,
                                  }}
                                  rowsMin={4}
                                  placeholder="Enter your reason for disapproval"
                                  onChange={(e) => setReasonMsg(e.target.value)}
                                />

                                <Button
                                  sx={{ minWidth: 100 }}
                                  type="submit"
                                  ml={10}
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleReasonSubmit(index, reasonMsg)
                                  }
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>
                  {/* **************************** End withdrawalRequest details ******************** */}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default Editrider;
