import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import RiderDiscount from "./RiderDiscount";
import CustomerDiscount from "./CustomerDiscount";



const Offer = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cities, setCities] = useState([]);
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };


  return (
    <div style={{ padding: "10px" }}>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
        sx={{ borderRadius: "10px" }}
      >
        <Tab
          sx={{
            background: selectedTab === 0 ? "#1976D2" : "#38b6ff",
            width: "100%",
          }}
          label="Customer Offer"
          style={{
            color: selectedTab === 0 ? "white" : "black",
            fontWeight: selectedTab === 0 ? "bold" : "normal",
          }}
        />
        <Tab
          sx={{
            background: selectedTab === 1 ? "#1976D2" : "#38b6ff",
            width: "100%",
          }}
          label="Rider Offer"
          style={{
            color: selectedTab === 1 ? "white" : "black",
            fontWeight: selectedTab === 1 ? "bold" : "normal",
          }}
        />
      </Tabs>

      {selectedTab === 0 && (
        <div>
          {/* Customer_offer */}
          <CustomerDiscount />
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          {/* Rider_offer */}
          <RiderDiscount />
        </div>
      )}
    </div>
  );
};

export default Offer;
