/* eslint-disable no-unused-expressions */
import { AddCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import helmet from "helmet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostData } from "../redux/action/action";
import NativeSelect from "@mui/material/NativeSelect";
import {
  collection,
  getDoc,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  validateNumariAndDecimal,
  validateAlphabetsOnly,
  validateRequiredandAlphabetOnly,
  validateMobileNum,
  validateNumaric,
} from "../common/validation";
import { db } from "../firebase-config";
const empCollectionRef = collection(db, "orderData");

const Edit = (props) => {
  const dispatch = useDispatch();
  const EditData = useSelector((state) => state.post);
  const [edata, setEdata] = useState();
  const [udata, setUdata] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [validationObject, setvalidationObject] = useState({
    orderContent: {
      error: false,
      errMessage: "",
    },
    orderType: {
      error: false,
      errMessage: "",
    },
    senderPhone: {
      error: false,
      errMessage: "",
    },
    reason: {
      error: false,
      errMessage: "",
    },
    totalDistance: {
      error: false,
      errMessage: "",
    },
    weight: {
      error: false,
      errMessage: "",
    },
    deliveredCount: {
      error: false,
      errMessage: "",
    },
    name: {
      error: false,
      errMessage: "",
    },
    contactNumber: {
      error: false,
      errMessage: "",
    },
    paybackAmount: {
      error: false,
      errMessage: "",
    },
    additionalDropCharges: {
      error: false,
      errMessage: "",
    },
    cashOnDeliveryCharges: {
      error: false,
      errMessage: "",
    },
    distanceCharges: {
      error: false,
      errMessage: "",
    },
    insuranceAmount: {
      error: false,
      errMessage: "",
    },
    orderAmount: {
      error: false,
      errMessage: "",
    },
    paymentRemark: {
      error: false,
      errMessage: "",
    },
    riderAmount: {
      error: false,
      errMessage: "",
    },
    weightCharges: {
      error: false,
      errMessage: "",
    },
    adminCommission: {
      error: false,
      errMessage: "",
    },
    promoCodeDiscount: {
      error: false,
      errMessage: "",
    },
    riderRewardAmount: {
      error: false,
      errMessage: "",
    },
  });
  useEffect(() => {
    dispatch(getPostData());
  }, []);

  useEffect(() => {
    if (EditData.data.length) {
      setEdata(EditData);
    } else {
      console.log("Data is not available");
    }
  }, [EditData]);

  // const data1 = [edata];
  console.log(edata?.data, "00000000");
  console.log(props.dataMain.state.from[0], "+++----0000");

  // useEffect(() => {
  //   for (let i = 0; i < edata?.data.length; i++) {
  //     if (props.dataMain.state.from[1] === edata?.data[i].orderId) {
  //       return setUdata([edata?.data[i]]);
  //     }
  //   }
  // }, [edata?.data, props.dataMain.state.from]);

  const weightCategoryMappings = {
    A: "0-5kg",
    B: "5-10kg",
    C: "10-20kg",
    D: "20-30kg",
  };

  useEffect(() => {
    for (let i = 0; i < edata?.data.length; i++) {
      if (props.dataMain.state.from[1] === edata?.data[i].orderId) {
        const weightCategory = edata?.data[i].weightCategory; // Assuming Firestore returns 'A', 'B', 'C', or 'D'
        return setUdata([edata?.data[i]], weightCategory);
      }
    }
  }, [edata?.data, props.dataMain.state.from]);

  const handleChange = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return { ...list, [e.target.name]: e.target.value };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleChanges = (e, i) => {
    setUdata((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return {
            ...list,
            pickUpLocation: {
              ...list.pickUpLocation,
              [e.target.name]: e.target.value,
            },
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleChanged = (e, i) => {
    // console.log(e.target.name , "@@@@2");
    setUdata((item) => {
      return item?.map((list, index) => {
        // console.log({...list} , "@@@11133");
        if (index === i) {
          return {
            ...list,
            paymentInformation: {
              ...list.paymentInformation,
              [e.target.name]: e.target.value,
            },
          };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };
  const handleChangies = (e, dataIndex, field) => {
    setUdata((items) =>
      items.map((item, index) => {
        if (index === 0) {
          // Assuming you want to update the first element of udata
          return {
            ...item,
            dropLocations: item.dropLocations.map((location, locIndex) => {
              if (locIndex === dataIndex) {
                return {
                  ...location,
                  [field]: e.target.value,
                };
              }
              return location;
            }),
          };
        }
        return item;
      })
    );
  };

  console.log(udata, "@@@@@123");

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };

    getUsers()
      .then((users) => {
        console.log(udata, "12233");
        const filteredUsers = users.filter(
          (user) => user.orderId === udata[0].orderId
        );
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            ...udata[0], // Use the desired array element or properties here
          };
          alert("Succesfully Updated!!!");
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const validateAllField = (name, event) => {
    switch (name) {
      case "orderContent":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "senderPhone":
        validationObject[event.target.name] = validateMobileNum(event);
        break;
      case "weight":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "reason":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "totalDistance":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "deliveredCount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "name":
        validationObject[event.target.name] = validateAlphabetsOnly(event);
        break;
      case "contactNumber":
        validationObject[event.target.name] = validateMobileNum(event);
        break;
      case "paybackAmount":
        validationObject[event.target.name] = validateNumaric(event);
        break;
      case "additionalDropCharges":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "cashOnDeliveryCharges":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "distanceCharges":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "insuranceAmount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "orderAmount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "riderAmount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "weightCharges":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "adminCommission":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "promoCodeDiscount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
      case "riderRewardAmount":
        validationObject[event.target.name] = validateNumariAndDecimal(event);
        break;
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Edit order details
              </Typography>
              {udata?.map((item, index) => {
                return (
                  <form autoComplete="off" key={index}>
                    <Paper mt={9}>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Order Content"
                        name="orderContent"
                        value={item.orderContent}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("orderContent", event);
                        }}
                        variant="outlined"
                        error={validationObject.orderContent?.error}
                        helperText={validationObject.orderContent?.errMessage}
                        m={2}
                      />
                      <FormControl
                        sx={{
                          m: 3,
                          minWidth: 220,
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Order Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name={"orderType"}
                          value={item.orderType}
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                          label="orderType"
                        >
                          <MenuItem value="Medical">{"Medical"}</MenuItem>
                          <MenuItem value="Clothing">{"Clothing"}</MenuItem>
                          <MenuItem value="Electronics">
                            {"Electronics"}
                          </MenuItem>
                          <MenuItem value="Other">{"Other"}</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Sender Phone"
                        name="senderPhone"
                        value={item.senderPhone}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("senderPhone", event);
                        }}
                        variant="outlined"
                        error={validationObject.senderPhone?.error}
                        helperText={validationObject.senderPhone?.errMessage}
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Reason"
                        name="reason"
                        value={item.reason}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("reason", event);
                        }}
                        variant="outlined"
                        error={validationObject.reason?.error}
                        helperText={validationObject.reason?.errMessage}
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Total Distance"
                        name="totalDistance"
                        type="text"
                        value={item.totalDistance}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("totalDistance", event);
                        }}
                        variant="outlined"
                        error={validationObject.totalDistance?.error}
                        helperText={validationObject.totalDistance?.errMessage}
                        m={2}
                      />
                      {/* <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Weight"
                        name="weight"
                        value={item.weight}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("weight", event);
                        }}
                        variant="outlined"
                        error={validationObject.weight?.error}
                        helperText={validationObject.weight?.errMessage}
                        m={2}
                      /> */}
                      <FormControl
                        variant="outlined"
                        m={2}
                        sx={{
                          m: 3,
                          minWidth: 220,
                        }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Weight category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name={"weightCategory"}
                          value={item.weightCategory} // Use the value from state
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                          label="Weight Category"
                        >
                          {Object.keys(weightCategoryMappings).map((key) => (
                            <MenuItem key={key} value={key}>
                              {weightCategoryMappings[key]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        m={2}
                        sx={{ m: 3, minWidth: 220 }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Order Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name={"orderStatus"}
                          value={item.orderStatus}
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                          label="Order Status"
                        >
                          <MenuItem value="Created">{"Created"}</MenuItem>
                          <MenuItem value="Assigned">{"Assigned"}</MenuItem>
                          <MenuItem value="Accepted">{"Accepted"}</MenuItem>
                          <MenuItem value="TripStarted">
                            {"TripStarted"}
                          </MenuItem>
                          <MenuItem value="Arrived">{"Arrived"}</MenuItem>
                          <MenuItem value="PickedUp">{"PickedUp"}</MenuItem>
                          <MenuItem value="inTransit">{"inTransit"}</MenuItem>
                          <MenuItem value="Cancelled">{"Cancelled"}</MenuItem>
                          <MenuItem value="Rejected">{"Rejected"}</MenuItem>
                          <MenuItem value="Returned">{"Returned"}</MenuItem>
                          <MenuItem value="Delivered">{"Delivered"}</MenuItem>
                          <MenuItem value="TripEnded">{"TripEnded"}</MenuItem>
                          <MenuItem value="hanged">{"hanged"}</MenuItem>
                          <MenuItem value="Pending">{"Pending"}</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Delivered Count"
                        name="deliveredCount"
                        value={item.deliveredCount}
                        onChange={(event) => {
                          handleChange(event, index);
                          validateAllField("deliveredCount", event);
                        }}
                        variant="outlined"
                        error={validationObject.deliveredCount?.error}
                        helperText={validationObject.deliveredCount?.errMessage}
                        m={2}
                      /> */}
                      <Divider my={6} />
                      <Typography variant="h6">Pick Up Address</Typography>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Name"
                        name="name"
                        value={item.pickUpLocation.name}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("name", event);
                        }}
                        variant="outlined"
                        error={validationObject.name?.error}
                        helperText={validationObject.name?.errMessage}
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Contact Number"
                        type="text"
                        name="contactNumber"
                        value={item.pickUpLocation.contactNumber}
                        onChange={(event) => {
                          handleChanges(event, index);
                          validateAllField("contactNumber", event);
                        }}
                        variant="outlined"
                        error={validationObject.contactNumber?.error}
                        helperText={validationObject.contactNumber?.errMessage}
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        multiline
                        label="Additional Information"
                        name="additionalInfo"
                        value={item.pickUpLocation.additionalInfo}
                        onChange={(e) => handleChanges(e, index)}
                        variant="outlined"
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Address"
                        multiline
                        //
                        name="address"
                        value={item.pickUpLocation.address}
                        onChange={(e) => handleChanges(e, index)}
                        variant="outlined"
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Delivery Status"
                        type="text"
                        name="deliveryStatus"
                        value={item.pickUpLocation.deliveryStatus}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />

                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Payback Amount"
                        type="number"
                        name="paybackAmount"
                        value={item.pickUpLocation.paybackAmount}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Payback Name"
                        type="text"
                        name="paybackName"
                        value={item.pickUpLocation.paybackName}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        m={2}
                      />

                      <Divider my={6} />

                      {item.dropLocations?.map((data, dataIndex) => (
                        <Card key={dataIndex}>
                          <Typography variant="h6">
                            Drop Up Address ({dataIndex + 1})
                          </Typography>
                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            id={`name-${dataIndex}`} // Unique id for this TextField
                            label="Name"
                            name={`name-${dataIndex}`} // Unique name for this TextField
                            value={data.name}
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "name")
                            }
                            variant="outlined"
                            m={2}
                          />

                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            id={`contactNumber-${dataIndex}`} // Unique id for this TextField
                            label="Contact Number"
                            type={"number"}
                            name={`contactNumber-${dataIndex}`} // Unique name for this TextField
                            value={data.contactNumber}
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "contactNumber")
                            }
                            variant="outlined"
                            m={2}
                          />

                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            id={`additionalInfo-${dataIndex}`} // Unique id for this TextField
                            multiline
                            label="Additional Information"
                            name={`additionalInfo-${dataIndex}`} // Unique name for this TextField
                            value={data.additionalInfo}
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "additionalInfo")
                            }
                            variant="outlined"
                            m={2}
                          />

                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            id={`address-${dataIndex}`} // Unique id for this TextField
                            label="Address"
                            multiline
                            //
                            name={`address-${dataIndex}`} // Unique name for this TextField
                            value={data.address}
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "address")
                            }
                            variant="outlined"
                            m={2}
                          />
                          <FormControl
                            variant="outlined"
                            m={2}
                            sx={{ m: 3, minWidth: 220 }}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Delivery Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id={`deliveryStatus-${dataIndex}`} // Unique id for this TextField
                              name={`deliveryStatus-${dataIndex}`} // Unique name for this TextField
                              value={data.deliveryStatus}
                              onChange={(e) =>
                                handleChangies(e, dataIndex, "deliveryStatus")
                              }
                              label="PaymentMode"
                            >
                              <MenuItem value="Created">{"Created"}</MenuItem>
                              <MenuItem value="Assigned">{"Assigned"}</MenuItem>
                              <MenuItem value="Accepted">{"Accepted"}</MenuItem>
                              <MenuItem value="TripStarted">
                                {"TripStarted"}
                              </MenuItem>
                              <MenuItem value="Arrived">{"Arrived"}</MenuItem>
                              <MenuItem value="PickedUp">{"PickedUp"}</MenuItem>
                              <MenuItem value="inTransit">
                                {"inTransit"}
                              </MenuItem>
                              <MenuItem value="Cancelled">
                                {"Cancelled"}
                              </MenuItem>
                              <MenuItem value="Rejected">{"Rejected"}</MenuItem>
                              <MenuItem value="Returned">{"Returned"}</MenuItem>
                              <MenuItem value="Delivered">
                                {"Delivered"}
                              </MenuItem>
                              <MenuItem value="TripEnded">
                                {"TripEnded"}
                              </MenuItem>
                              <MenuItem value="hanged">{"hanged"}</MenuItem>
                              <MenuItem value="Pending">{"Pending"}</MenuItem>

                              {/* {statesChildren} */}
                            </Select>
                          </FormControl>
                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            label="Payback Amount"
                            type="number"
                            id={`paybackAmount-${dataIndex}`} // Unique id for this TextField
                            name={`paybackAmount-${dataIndex}`} // Unique name for this TextField
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "paybackAmount")
                            }
                            value={data.paybackAmount}
                            variant="outlined"
                            m={2}
                          />
                          <TextField
                            sx={{ m: 3, minWidth: 220 }}
                            label="Payback Name"
                            type="text"
                            value={data.paybackName}
                            id={`paybackName-${dataIndex}`} // Unique id for this TextField
                            name={`paybackName-${dataIndex}`} // Unique name for this TextField
                            onChange={(e) =>
                              handleChangies(e, dataIndex, "paybackName")
                            }
                            variant="outlined"
                            m={2}
                          />
                        </Card>
                      ))}

                      <Divider my={6} />
                      <Typography variant="h6">Payment Information</Typography>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="AdditionalDrop Charge"
                        name="additionalDropCharges"
                        disabled
                        value={item.paymentInformation.additionalDropCharges}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("additionalDropCharges", event);
                        }}
                        error={validationObject.additionalDropCharges?.error}
                        helperText={
                          validationObject.additionalDropCharges?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      />
                      {/* <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="CaseDelivery charge"
                        name="cashOnDeliveryCharges"
                        value={item.paymentInformation.cashOnDeliveryCharges}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("cashOnDeliveryCharges", event);
                        }}
                        error={validationObject.cashOnDeliveryCharges?.error}
                        helperText={
                          validationObject.cashOnDeliveryCharges?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      /> */}
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Distance Charge"
                        name="distanceCharges"
                        disabled
                        value={item.paymentInformation.distanceCharges}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("distanceCharges", event);
                        }}
                        variant="outlined"
                        error={validationObject.distanceCharges?.error}
                        helperText={
                          validationObject.distanceCharges?.errMessage
                        }
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Insurance Amount"
                        name="insuranceAmount"
                        disabled
                        value={item.paymentInformation.insuranceAmount}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("insuranceAmount", event);
                        }}
                        variant="outlined"
                        error={validationObject.insuranceAmount?.error}
                        helperText={
                          validationObject.insuranceAmount?.errMessage
                        }
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Order Amount"
                        name="orderAmount"
                        disabled
                        value={item.paymentInformation.orderAmount}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("orderAmount", event);
                        }}
                        error={validationObject.orderAmount?.error}
                        helperText={validationObject.orderAmount?.errMessage}
                        variant="outlined"
                        m={2}
                      />

                      <FormControl
                        variant="outlined"
                        m={4}
                        sx={{ m: 3, minWidth: 220 }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          payment mode
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name={"paymentMode"}
                          disabled
                          label="Payment Mode"
                          value={item.paymentInformation.paymentMode}
                          onChange={(event) => handleChanged(event, index)}
                        >
                          <MenuItem value="Case">{"Case"}</MenuItem>
                          <MenuItem value="COD">{"COD"}</MenuItem>
                          <MenuItem value="Online">{"Online"}</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Payment Remark"
                        name="paymentRemark"
                        disabled
                        value={item.paymentInformation.paymentRemark}
                        onChange={(e) => handleChanged(e, index)}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Rider Amount"
                        type="text"
                        disabled
                        name="riderAmount"
                        value={item.paymentInformation.riderAmount}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("riderAmount", event);
                        }}
                        error={validationObject.riderAmount?.error}
                        helperText={validationObject.riderAmount?.errMessage}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Weight Charges"
                        type="text"
                        disabled
                        name="weightCharges"
                        value={item.paymentInformation.weightCharges}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("weightCharges", event);
                        }}
                        error={validationObject.weightCharges?.error}
                        helperText={validationObject.weightCharges?.errMessage}
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Admin Commission"
                        type="text"
                        disabled
                        name="adminCommission"
                        value={item.paymentInformation.adminCommission}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("adminCommission", event);
                        }}
                        error={validationObject.adminCommission?.error}
                        helperText={
                          validationObject.adminCommission?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      />
                      {/* <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Cash OnDelivery Charges"
                        type="text"
                        name="cashOnDeliveryCharges"
                        value={item.paymentInformation.cashOnDeliveryCharges}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("cashOnDeliveryCharges", event);
                        }}
                        error={validationObject.cashOnDeliveryCharges?.error}
                        helperText={
                          validationObject.cashOnDeliveryCharges?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      /> */}
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="PromoCode Discount"
                        type="text"
                        disabled
                        name="promoCodeDiscount"
                        value={item.paymentInformation.promoCodeDiscount}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("promoCodeDiscount", event);
                        }}
                        error={validationObject.promoCodeDiscount?.error}
                        helperText={
                          validationObject.promoCodeDiscount?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      />
                      <TextField
                        sx={{ m: 3, minWidth: 220 }}
                        id="standard"
                        label="Rider Reward Amount"
                        type="text"
                        name="riderRewardAmount"
                        value={item.paymentInformation.riderRewardAmount}
                        onChange={(event) => {
                          handleChanged(event, index);
                          validateAllField("riderRewardAmount", event);
                        }}
                        error={validationObject.riderRewardAmount?.error}
                        helperText={
                          validationObject.riderRewardAmount?.errMessage
                        }
                        variant="outlined"
                        m={2}
                      />
                      {/* <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Rider Amount"
                      
                      name={"pincode"}
                      // value={taskData.pickup_address?.pincode}
                      // onChange={addPickupAddress}
                      variant="outlined"
                      m={2}
                    /> */}
                      {/* <TextField
                      sx={{ m: 3, minWidth: 220 }}
                      id="standard"
                      label="Payment remark"
                      multiline
                      // 
                      name={"Payment remark"}
                      // value={taskData.pickup_address?.address_line_2}
                      // onChange={addDropAddress}
                      variant="outlined"
                      m={2}
                    /> */}
                    </Paper>
                    <Button
                      sx={{ m: 3, minWidth: 220 }}
                      type="submit"
                      ml={10}
                      variant="contained"
                      size="large"
                      color="primary"
                      mt={3}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </form>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Edit;
