export const validateRequiredField = (event) => {
  if (event.target.value === "") {
    return { error: true, errMessage: "This is required" };
  } else {
    return { error: false, errMessage: "" };
  }
};

export const validateMobileNum = (e) => {
  console.log(e);
  let input = e.target.value;
  let pattern = /^\d+$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Mobile Num can only have Numbers" };
  } else {
    if (input.length == 10) {
      return { error: false, errMessage: "" };
    }
    return { error: true, errMessage: "Mobile No. should be of length 10" };
  }
};
export const validateAccountNum = (e) => {
  console.log(e);
  let input = e.target.value;
  let pattern = /^\d+$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Account Num can only have Numbers" };
  } else {
    if (input.length == 15) {
      return { error: false, errMessage: "" };
    }
    return { error: true, errMessage: "Account No. should be of length 15" };
  }
};

export const validateNumaric = (e) => {
  let input = e.target.value;
  let pattern = /^\d+$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Only Number Value" };
  } else {
    //if(input.length!=6)return ({ error: true, errMessage:"length should be 6"});
    return { error: false, errMessage: "" };
  }
};

export const validateNumariAndDecimal = (e) => {
  let input = e.target.value;
  let pattern = /^\d*\.?\d*$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Only Number and Decimal Value" };
  } else {
    //if(input.length!=6)return ({ error: true, errMessage:"length should be 6"});
    return { error: false, errMessage: "" };
  }
};
export const validateNumariAndCapital = (e) => {
  let input = e.target.value;
  let pattern = /^[0-9A-Z]*$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return {
      error: true,
      errMessage: "Only numbers and capital letters are allowed",
    };
  } else {
    //if(input.length!=6)return ({ error: true, errMessage:"length should be 6"});
    return { error: false, errMessage: "" };
  }
};

export const validateRequiredandAlphabetOnly = (e) => {
  let input = e.target.value;
  let pattern = /^[A-Za-z]+$/;
  if (!input) {
    let res = validateRequiredField(e);
    return res;
  }
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Only Alphabets" };
  } else {
    return { error: false, errMessage: "" };
  }
};
export const validateAlphabetsOnly = (e) => {
  let input = e.target.value;
  let pattern = /^[a-zA-Z][a-zA-Z ]+$/;
  if (!input.match(pattern)) {
    return { error: true, errMessage: "Only Alphabets" };
  } else {
    return { error: false, errMessage: "" };
  }
};
