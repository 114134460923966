// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPmYdQSBgJx7RfJxG7gGxbEoDxAn2TsdA",
  authDomain: "three-parcel-385706.firebaseapp.com",
  projectId: "three-parcel-385706",
  storageBucket: "three-parcel-385706.appspot.com",
  messagingSenderId: "935346764396",
  appId: "1:935346764396:web:1ac208903e014826203649",
  measurementId: "G-E2JNRP1J93",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
