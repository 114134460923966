import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  Chip,
  Drawer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useLocation } from "react-router-dom";
import { getPostData } from "../redux/action/rider_action";
import { useDispatch, useSelector } from "react-redux";
import { MenuOpen } from "@mui/icons-material";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";
const empCollectionRef = collection(db, "serviceable_places");
const empCollectionRefRider = collection(db, "ridersData");
const profileIcon = require("../assets/icons/orders/Profile.png");
const aadher = require("../assets/icons/orders/aadher.jpg");
const rcrc = require("../assets/icons/orders/rcrc.jpg");
const electricbill = require("../assets/icons/orders/electricbill.jpg");
const numberplate = require("../assets/icons/orders/numberplate.jpg");
const vehicle = require("../assets/icons/orders/vehicle.png");
const selfi = require("../assets/icons/orders/selfi.jpg");

const zoomedImageStyle = {
  transition: "transform 0.3s, position 0.3s",
  cursor: "pointer",
  textAlign: "center",
};

const zoomedImageZoomedStyle = {
  transform: "scale(2)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  zIndex: 1000,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const zoomableImageStyle = {
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  transition: "transform 0.3s",
};

const Riderslist = () => {
  const dispatch = useDispatch();
  const [zoomed, setZoomed] = useState(false);
  const location = useLocation();
  const walletId = new URLSearchParams(location.search).get("walletId");

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };
  const userdata = useSelector((state) => state.post);
  const [ridersData, setRidersData] = useState();
  const [formattedData, setFormattedData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [ddata, setDdata] = useState();
  const [udata, setUdata] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesArea, setCitiesArea] = useState([]);
  const [riderName, setRiderName] = useState([]);
  // const [selectArea, setSelectArea] = useState([])
  const [selectedCity, setSelectedCity] = useState(null); // Initialize with an empty string
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedRiderName, setSelectedRiderName] = useState(null);
  const [selectedRider, setSelectedRider] = useState("");
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [orderCount, setOrderCount] = useState(0);

  const [walletData, setWalletData] = useState([]); // Consolidate walletData state

  // ... (your existing code)

  const handleDownloadExcel = () => {
    if (!formattedData || formattedData.length === 0) {
      return;
    }

    const walletDetails = walletData.filter(
      (data) => data.id === formattedData[0].walletId
    )[0];

    if (!walletDetails) {
      return;
    }

    const content = `Rider ID, Total Order, Total Balance, Reward Balance, Claim Balance, COD Balance, Referral Balance
    ${formattedData[0].riderId}, ${orderCount}, ${walletDetails.totalBalance}, ${walletDetails.rewardBalance}, ${walletDetails.claimBalance}, ${walletDetails.codBalance}, ${walletDetails.referralBalance}
  `;

    const blob = new Blob([content], { type: "application/csv" });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = `RiderDetails_${formattedData[0].riderId}.csv`;
    link.click();
  };

  // Function to fetch order data count for a specific rider
  const fetchOrderCount = async (riderId) => {
    try {
      const snapshot = await getDocs(collection(db, "orderData"));
      let orderCount = 0;

      snapshot.forEach((doc) => {
        const orderData = doc.data();
        if (
          orderData.activeRider &&
          orderData.activeRider.riderId === riderId
        ) {
          orderCount++;
        }
      });

      setOrderCount(orderCount);
    } catch (error) {
      console.error("Error retrieving order count:", error);
    }
  };

  // console.log("++++++++++++++++", udata)

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    // filteredData()
  };
  const handleDeliverClick = (e) => {
    setSelectedCity(e.target.value);
    console.log("ccdcdcd", ridersData);
    const DeliveredRows = ridersData?.data.filter(
      (row) => row.workingCity === e.target.value
      // (row)=>console.log("rowssssss", row)
    );
    setDdata(DeliveredRows);
  };

  const handleRiderChange = (e) => {
    setSelectedRider(e.target.value);
  };
  // const handleRiderChange = (e) => {
  //   setSelectedCity(e.target.value)
  //   console.log("ccdcdcd", ridersData)
  //   const DeliveredRows = ridersData?.data.filter(
  //     (row) => row.workingCity === e.target.value
  //     // (row)=>console.log("rowssssss", row)
  //   );
  //   setDdata(DeliveredRows);
  // };

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  console.log("Rider data", userdata);
  useEffect(() => {
    if (userdata.data.length) {
      setRidersData(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]); // Add 'userdata' as a dependency

  console.log("Rider data:", ridersData);

  console.log(ridersData?.data[1]?.riderId, "345666");

  const handleOpen = async (item, row) => {
    for (let i = 0; i < ridersData.data.length; i++) {
      if (item[0] === ridersData.data[i].riderId) {
        setFormattedData([ridersData.data[i]]);
        setOpenDrawer(true);

        // Fetch order data count for the selected rider
        await fetchOrderCount(ridersData.data[i].riderId);
      }
    }
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const [selectedRowData, setSelectedRowData] = useState(null);

  // const handleRowClick = (rowData) => {
  //   const walletId = rowData.walletId; // Assuming walletId is part of your rowData

  //   // Redirect to TransactionDetails page with the walletId as a query parameter
  //   return (
  //     <Link to={`/transaction-details?walletId=${walletId}`}>Details</Link>
  //   );
  // };

  // *****************************  wallet logic *********************

  const walletDataCollectionRef = collection(db, "walletData");
  //City Area, select Area, rider name

  // Function to fetch wallet data
  const fetchWalletData = async () => {
    try {
      const snapshot = await getDocs(walletDataCollectionRef);
      const walletData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWalletData(walletData);
    } catch (error) {
      console.error("Error retrieving wallet data:", error);
    }
  };

  useEffect(() => {
    // Fetch wallet data when the component mounts
    fetchWalletData();
  }, []);

  // ***************************** End  wallet logic *********************

  useEffect(() => {
    const citiesList = () => {
      const getCities = async () => {
        try {
          const snapshot = await getDocs(empCollectionRef);
          console.log("snapshot++++++", snapshot);
          return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error retrieving users:", error);
          return [];
        }
      };
      getCities()
        .then((users) => {
          // console.log("users++++++", users)
          setCities(users);

          // const filteredUsers = users.filter((user) => user.id === udata[0].id);
          // if (filteredUsers.length > 0) {
          //   const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          //   if (!docId) {
          //     console.error("Document ID is missing or invalid.");
          //     return;
          //   }

          const empDocRef = doc(empCollectionRef);
          console.log("empDocRef++++++++++++", empDocRef);

          // const updateData = {
          //   ...udata, // Use the desired array element or properties here
          // };

          // console.log("SDSDSDSD", udata)
          // updateDoc(empDocRef, updateData)
          //   .then(() => {
          //     console.log("Data updated successfully!");
          //   })
          //   .catch((error) => {
          //     console.error("Error updating document:", error);
          //   });
          // } else {
          //   console.log("No matching users found.");
          // }
        })
        .catch((error) => console.error(error));
    };
    citiesList();
  }, []);

  //City Area, select Area, rider name
  useEffect(() => {
    const areaList = () => {
      const getArea = async () => {
        try {
          const snapshot = await getDocs(empCollectionRefRider);
          console.log("snapshot++++++", snapshot);
          return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error retrieving users:", error);
          return [];
        }
      };
      getArea()
        .then((users) => {
          console.log("users++++++", users);
          setCitiesArea(users);

          const filteredUsers = users.filter((user) => user.id === udata[0].id);
          if (filteredUsers.length > 0) {
            const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
            if (!docId) {
              console.error("Document ID is missing or invalid.");
              return;
            }

            const empDocRef = doc(empCollectionRefRider);
            console.log("empDocRef++++++++++++", empDocRef);

            // const updateData = {
            //   ...udata, // Use the desired array element or properties here
            // };

            // console.log("SDSDSDSD", udata)
            // updateDoc(empDocRef, updateData)
            //   .then(() => {
            //     console.log("Data updated successfully!");
            //   })
            //   .catch((error) => {
            //     console.error("Error updating document:", error);
            //   });
            // } else {
            //   console.log("No matching users found.");
            // }
          }
        })
        .catch((error) => console.error(error));
    };
    areaList();
  }, []);

  //City Area, select Area, rider name
  useEffect(() => {
    const riderList = () => {
      const riderName = async () => {
        try {
          const snapshot = await getDocs(empCollectionRefRider);
          console.log("snapshot++++++", snapshot);
          return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error retrieving users:", error);
          return [];
        }
      };
      riderName()
        .then((users) => {
          console.log("rideUsers++++++", users);
          setRiderName(users);

          const filteredUsers = users.filter((user) => user.id === udata[0].id);
          if (filteredUsers.length > 0) {
            const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
            if (!docId) {
              console.error("Document ID is missing or invalid.");
              return;
            }

            const empDocRef = doc(empCollectionRefRider);
            console.log("empDocRef++++++++++++", empDocRef);

            // const updateData = {
            //   ...udata, // Use the desired array element or properties here
            // };

            // console.log("SDSDSDSD", udata)
            // updateDoc(empDocRef, updateData)
            //   .then(() => {
            //     console.log("Data updated successfully!");
            //   })
            //   .catch((error) => {
            //     console.error("Error updating document:", error);
            //   });
            // } else {
            //   console.log("No matching users found.");
            // }
          }
        })
        .catch((error) => console.error(error));
    };
    riderList();
  }, []);

  // const CustomRow = ({ rowData, rowIndex }) => (
  //   <TableRow style={{ background: "blue" }}>
  //     {rowData.map((cellData, cellIndex) => (
  //       <TableCell key={cellIndex}>{cellData}</TableCell>
  //     ))}
  //   </TableRow>
  // );

  const cityListChange = (e) => {
    setSelectedCity(e.target.value);
    const getArea = async () => {
      try {
        const snapshot = await getDocs(empCollectionRefRider);
        // console.log("snapshot++++++", snapshot)
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getArea()
      .then((users) => {
        console.log("Riders++++++", users);
        // setCities(users)

        const filteredUsers = users.filter((user) => user.id === udata[0].id);
        if (filteredUsers.length > 0) {
          console.log("filteredUsers+++++++++++++++++++++++", filteredUsers);
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRefRider);
          // console.log("empDocRef++++++++++++", empDocRef)
        }
      })
      .catch((error) => console.error(error));
  };

  const options = {
    filter: false, // Hide the filter bar
    searchOpen: true,
    search: false,
    selectableRows: "multiple",
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),

    customToolbar: () => {
      return (
        <div style={{ padding: "15px" }}>
          {" "}
          <h2
            style={{
              position: "absolute",
              top: "30px",
              left: "10px",
              zIndex: 999,
            }}
          >
            Rider details
          </h2>
          {/* <div
            style={{
              display: "flex",
              alignContent: "center",
              // marginTop: "-8%",
              marginRight: "15rem",
              alignItems: "center",
            }}
          >
            <input
              style={{
                marginRight: "10px",
                padding: "10px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "40%",
              }}
              type="date"
              placeholder="Start Date"
            />

            <input
              style={{
                marginRight: "10px",
                padding: "8px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "40%",
              }}
              type="date"
              placeholder="End Date"
            />
          </div> */}
        </div>
      );
    },

    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div
          style={{
            position: "absolute",
            marginTop: "15px", // Adjust this value to control the gap
            right: "10px",
            zIndex: 999,
          }}
        >
          <input
            style={{
              borderRadius: "4px",
              height: "22px",
              border: "1px solid",
              borderColor: "#38b6ff",
              marginRight: "10px",
              padding: "5px",
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
      );
    },
  };

  // console.log(ddata, "--------");
  function formatTime(sec) {
    const date = new Date(sec * 1000);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${date.toLocaleDateString()} :: ${hours}:${minutes}:${seconds}`;
  }

  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;

      return rowData;
    };

    // const fData = {
    //   name:"susheel",
    //   address:"Delhi"
    // }

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Link
            to="/editriderdetails"
            onClick={handleRowClick}
            state={{ from: handleRowClick() }}
          >
            <Button variant="contained" size="small" color="primary">
              <EditIcon fontSize="small" />
            </Button>
          </Link>
          {/* Additional buttons and components */}
        </Stack>
      </>
    );
  };

  const columns = [
    {
      name: "riderId",
      label: "Rider ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "userType",
      label: "User Type",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "workingCity",
      label: "Working City",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "walletId",
      label: "Wallet  id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "employmentType",
      label: "Employment Type",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "firstName",
      label: "Rider Name",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "activeOrders",
      label: "Active Order",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "accountStatus",
      label: "account Status",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number ",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];

  return (
    <Grid>
      <div style={{}}>
        <FormControl variant="outlined" m={2} sx={{ m: 3, minWidth: 220 }}>
          <InputLabel id="demo-simple-select-outlined-label">
            Select city
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            required={true}
            name={"task_type"}
            label="Select city"
            onChange={handleDeliverClick}
            value={selectedCity}
          >
            {/* <MenuItem value="">
          <em>None</em>
          </MenuItem> */}

            {cities.map((city) => (
              // console.log("Cityyyyy", city)
              <MenuItem key={city.id} value={city.id}>
                {city.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" m={2} sx={{ m: 3, minWidth: 220 }}>
          <InputLabel id="demo-simple-select-outlined-label">
            Select Area
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            required={true}
            name={"task_type"}
            label="Select Area"
            onChange={handleAreaChange}
            value={selectedArea}
          >
            {citiesArea.map((city) => (
              // console.log("CityyyyyAreaaaaaaaaaaaaaaaaaaaaaaa", city.workingLocation)
              <MenuItem key={city.id} value={city.workingLocation}>
                {city.workingLocation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" m={2} sx={{ m: 3, minWidth: 220 }}>
          <InputLabel id="demo-simple-select-outlined-label">
            Rider name
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            required={true}
            name={"task_type"}
            label="Rider name"
            value={selectedRider}
            onChange={handleRiderChange}
          >
            {riderName.map((name) => {
              return (
                <MenuItem
                  key={name.id}
                  value={name.firstName + " " + name.lastName}
                  sx={{ textTransform: "capitalize" }}
                >
                  {name.firstName + " " + name.lastName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Grid style={{ marginTop: "20px", height: "100%", padding: "10px" }}>
        <MUIDataTable
          data={ddata ? ddata : ridersData?.data}
          // data={filteredData}
          columns={columns}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index, riderItem) => {
          return (
            <div style={{ padding: 20, width: "985px" }} key={index}>
              <Typography variant="h5" style={{ marginBottom: 10 }}>
                Rider Details
              </Typography>
              <Typography variant="h5" style={{ marginBottom: 2 }}>
                RIDER ID : {item.riderId}
              </Typography>
              <Typography variant="h5" style={{ marginBottom: 2 }}>
                Total Order : {orderCount}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                  gap: "10px",
                }}
              >
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#c5e7b5",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Wallet ID</Typography>
                  <br />
                  {item.walletId}
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Created Data</Typography>
                  <br />
                  <div>{formatTime(item.createdAt.seconds)}</div>
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Updated Data</Typography>
                  <br />
                  <div>{formatTime(item.updatedAt.seconds)}</div>
                </box>
              </div>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ############################## Rider detaila ######################## */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                  <Typography
                    variant="h6"
                    style={{
                      ...zoomedImageStyle,
                      ...(zoomed && zoomedImageZoomedStyle),
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={toggleZoom}
                  >
                    {" "}
                    <img
                      style={{
                        ...zoomableImageStyle,
                        ...(zoomed && { transform: "scale(2)" }),
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                      }}
                      src={
                        item.profilePicture ||
                        "https://www.w3schools.com/w3images/avatar6.png"
                      }
                      alt="Profile"
                    />
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.firstName} {item.lastName}
                  </Typography>{" "}
                  <br />
                  <Typography
                    sx={{
                      justifyContent: "center",
                      marginBottom: 2,
                      marginLeft: 10,
                    }}
                  >
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Onduty:</strong>
                            {item.onDuty}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>OrderCancleCount:</strong>{" "}
                            {item.orderCancelCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Account status:</strong>
                            {item.accountStatus}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Active_Orders:</strong> {item.activeOrders}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Address:</strong>
                            {item.address}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Current location:</strong>{" "}
                            {item.currentLocation._lat} ::
                            {item.currentLocation._long}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Phone_No:</strong>
                            {item.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Rating:</strong> {item.ratings}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Role:</strong>
                            {item.role}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>User_Type:</strong>
                            {item.userType}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Employement_Type:</strong>
                            {item.employmentType}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Gender:</strong>
                            {item.gender}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>workingLocation:</strong>
                            {item.workingLocation}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>WorkingCity:</strong>
                            {item.workingCity}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Applied_referralcode:</strong>
                            {item.appliedReferralCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Refercode:</strong>
                            {item.referCode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Role:</strong>
                            {item.role}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>User_Type:</strong>
                            {item.role}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>IsFirstOrderDelivered:</strong>
                            {item.isFirstOrderDelivered}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Reason:</strong>
                            {item.reason}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>walletId:</strong>
                            {item.walletId}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Typography>
                </box>
              </div>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ##############################  End Rider detaila ######################## */}
              {/* **************************** Mobile  details ******************** */}
              <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                <Typography variant="h6">Mobile Device details</Typography>

                <Typography
                  sx={{
                    justifyContent: "center",
                    marginBottom: 2,
                    marginLeft: 12,
                  }}
                >
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>appVersion:</strong>
                          {item.mobileDevice.appVersion}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>deviceName:</strong>{" "}
                          {item.mobileDevice.deviceName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>firstLogin:</strong>
                          {formatTime(item.mobileDevice.firstLogin.seconds)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>lastLogin:</strong>{" "}
                          {formatTime(item.mobileDevice.lastLogin.seconds)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>imei:</strong>
                          {item.mobileDevice.imei}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>platform:</strong>{" "}
                          {item.mobileDevice.platform}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ****************************  end Mobile details ******************** */}
              {/* **************************** Bank account details ******************** */}
              <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                <Typography variant="h6">Bank account details</Typography>
                <Typography
                  sx={{
                    justifyContent: "center",
                    marginBottom: 2,
                    marginLeft: 12,
                  }}
                >
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>accountName:</strong>
                          {item.bankAccount.accountName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>accountNumber:</strong>{" "}
                          {item.bankAccount.accountNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>bankName:</strong>
                          {item.bankAccount.bankName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>ifscCode:</strong> {item.bankAccount.ifscCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>panNumber:</strong>
                          {item.bankAccount.panNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* **************************** End Bank account details ******************** */}

              {/* **************************** Wallet details ******************** */}
              <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Wallet details</Typography>
                  <Button
                    variant="h6"
                    color="primary"
                    style={{ backgroundColor: "#52595D" }}
                  >
                    {" "}
                    <Link
                      to={{
                        pathname: "/transaction",
                        search: `?walletId=${item.walletId}`,
                      }}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Transaction details
                    </Link>{" "}
                  </Button>
                </div>
                {walletData
                  .filter((data) => data.id === item.walletId)
                  .map((filteredData) => (
                    <Typography
                      sx={{
                        justifyContent: "center",
                        marginBottom: 2,
                        marginLeft: 12,
                      }}
                    >
                      <Typography sx={{ display: "flex", marginLeft: 10 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Total Balance:</strong>
                              {filteredData.totalBalance}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Reward Balance:</strong>{" "}
                              {filteredData.rewardBalance}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography sx={{ display: "flex", marginLeft: 10 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Claim Balance:</strong>
                              {filteredData.claimBalance}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>COD Balance:</strong>{" "}
                              {filteredData.codBalance}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography sx={{ display: "flex", marginLeft: 10 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Referral Balance:</strong>
                              {filteredData.referralBalance}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Typography>
                  ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadExcel}
                >
                  Download Excel
                </Button>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* **************************** End Wallet  details ******************** */}
              {/* **************************** Vehicle details ******************** */}
              <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                <Typography variant="h6">Vehicle details</Typography>
                <Typography
                  sx={{
                    justifyContent: "center",
                    marginBottom: 2,
                    marginLeft: 12,
                  }}
                >
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>lisencePlateColor:</strong>
                          {item.vehicle.lisencePlateColor}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>vehicleBrand:</strong>{" "}
                          {item.vehicle.vehicleBrand}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>vehicleColor:</strong>
                          {item.vehicle.vehicleColor}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>vehicleModel:</strong>{" "}
                          {item.vehicle.vehicleModel}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>year:</strong>
                          {item.vehicle.year}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* **************************** End Vehicle details ******************** */}
              {/* **************************** Refered user details ******************** */}
              <div>
                {item.referredUser.map((data, index) => (
                  <Card
                    style={{ padding: 15, marginTop: 10, flex: 1 }}
                    key={index}
                  >
                    <Typography variant="h6">Referreduser</Typography>
                    <Typography sx={{ mt: 3 }}>
                      <Typography sx={{ display: "flex", marginLeft: 10 }}>
                        <Grid container spacing={2}>
                          {/* <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>isFirstOrderDelivered:</strong>
                              {data.isFirstOrderDelivered}
                            </Typography>
                          </Grid> */}
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>userId:</strong> {data.userId}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Typography>
                  </Card>
                ))}
              </div>
              {/* **************************** End Refered user details ******************** */}
            </div>
          );
        })}
      </Drawer>
    </Grid>
  );
};

export default Riderslist;
