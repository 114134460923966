import { createAsyncThunk } from "@reduxjs/toolkit";
// import { db } from "./firebase-config";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";

const empCollectionRef = collection(db, "customerData");

export const getPostData = createAsyncThunk(
  "posts/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getDocs(empCollectionRef);
      const mydata = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      return mydata;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
