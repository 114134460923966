import React, { useState } from "react";
import { Button, TextField, Grid, Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RiderDiscount = () => {
  const [editMode1, setEditMode1] = useState(false);

  const handleEditButtonClick1 = () => {
    setEditMode1(true);
  };

  const handleSubmitButtonClick1 = () => {
    setEditMode1(false);
  };
  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Rider Referral offer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Typography>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography>Referral Offer</Typography>
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">From:</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          inputProps={{ style: { height: "10px" } }}
                          disabled={!editMode1}
                          type="number"
                          id="outlined-disabled"
                          label="50"
                          defaultValue="Hello World"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">To:</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          inputProps={{ style: { height: "10px" } }}
                          disabled={!editMode1}
                          type="number"
                          id="outlined-disabled"
                          label="0.3"
                          defaultValue="Hello World"
                        />
                      </Grid>
                    </Grid>
                  </>
                  {editMode1 ? (
                    <Button
                      sx={{ m: 3, maxWidth: "70px", marginLeft: "90%" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      onClick={handleSubmitButtonClick1}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      sx={{ m: 3, maxWidth: "70px", marginLeft: "90%" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      onClick={handleEditButtonClick1}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default RiderDiscount;
