import React from "react";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Sidenav from "../components/Sidenav";
import Editrider from "../rider/Editrider";
import { useLocation } from "react-router-dom";

const Editrider_detail = () => {
  const dataMain = useLocation();
  console.log("main data", dataMain);
  return (
    <>
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 0.3 }}>
            <Editrider dataMain={dataMain} />
          </Box>
        </Box>
      </div>
    </>
  );
};
export default Editrider_detail;
